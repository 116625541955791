import { IActivity } from '../../interfaces';
import { ApiService } from '../ApiService';



class ActivityService extends ApiService<IActivity> {
  constructor() {
    super('Activity');
  }

  // Additional methods specific to the User model can be added here
}

export const activityService = new ActivityService();