import React, { useCallback, useEffect, useMemo, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import "./inspections.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { ButtonTypes } from "devextreme-react/cjs/button";
import { InspectionForm } from "./forms/inspection-form";
import { useLoad, useNavigation } from "../../contexts";
import { inspectionService } from "../../api";
import { IInspectionCreationForm, IPreworkInspection } from "../../interfaces";
import { List } from "devextreme-react";

export default function Inspections() {
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useAuth0();
  const { showLoader } = useLoad();
  const [showInspectionCreationView, setShowInspectionCreationView] =
    useState(false);
  const { setShowHeader } = useNavigation();
  const [submittedInspections, setSubmittedInspections] = useState<
    IInspectionCreationForm[]
  >([]);
  const [inspections, setInspections] = useState<IPreworkInspection[]>([]);

  useEffect(() => {
    setShowHeader(!showInspectionCreationView);
  }, [showInspectionCreationView, setShowHeader]);

  const fetchInspections = useCallback(async () => {
    showLoader(true);
    try {
      const response = await inspectionService.getAllByRegion();
      if (response.success) {
        const activeItems = response.data?.filter((i) => i.isActive) || [];
        setInspections(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, [showLoader]);

  const fetchSubmittedInspections = useCallback(async () => {
    showLoader(true);
    try {
      const response = await inspectionService.getSubmittedInspections(
        user?.email || ""
      );
      if (response.success) {
        const sortedInspections = response.data.sort(
          (a, b) =>
            (b.createdDate
              ? new Date(b.createdDate).getTime()
              : new Date().getTime()) -
            (a.createdDate
              ? new Date(a.createdDate).getTime()
              : new Date().getTime())
        );
        setSubmittedInspections(sortedInspections);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, [showLoader, user?.email]);

  useEffect(() => {
    fetchInspections();
    fetchSubmittedInspections();
  }, [fetchInspections, fetchSubmittedInspections]);

  const { logout } = useAuth0();
  const handleLogout = useCallback(() => {
    localStorage.clear();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }, [logout]);

  const backButtonOptions = {
    icon: "login",
    onClick: handleLogout,
  };

  const addButtonOptions = {
    icon: "plus",
    onClick: () => {
      setShowInspectionCreationView(true);
    },
  };

  const searchButtonOptions = {
    icon: "search",
    onClick: () => {
      setSearchBarVisible(!isSearchBarVisible); // Toggle visibility
    },
  };

  const closeSearchButtonOptions = useMemo<ButtonTypes.Properties>(
    () => ({
      icon: "back",
      stylingMode: "text",
      onClick: () => {
        setSearchBarVisible(false);
        setSearchTerm("");
      },
    }),
    []
  );

  const closeInspectionCreationForm = () => {
    setShowInspectionCreationView(false);
  };

  const onInspectionAdded = () => {
    fetchSubmittedInspections();
    setShowInspectionCreationView(false);
  };

  const renderListItem = (submittedInspection: IInspectionCreationForm) => {
    const inspectionDetail = inspections.find(
      (inspection) => inspection.id === submittedInspection.inspectionId
    );

    return (
      <div className="card-container">
        <Toolbar className="first-toolbar">
          <Item location="before" text={inspectionDetail?.name} />
        </Toolbar>
        <Toolbar>
          <Item
            location="before"
            render={() => <i className="dx-icon-event"></i>}
          />
          <Item location="before" text="Inspection Date:" />
          <Item
            location="after"
            text={
              submittedInspection?.createdDate
                ? new Date(
                    submittedInspection.createdDate + "Z"
                  )?.toLocaleString()
                : new Date()?.toLocaleDateString()
            }
          />
        </Toolbar>
        <Toolbar className="last-toolbar">
          <Item location="before" text="Status:" />
          <Item
            location="after"
            cssClass={`inspection-status ${
              submittedInspection.isPassed ? "pass" : "fail"
            }`}
            text={submittedInspection.isPassed ? "Pass" : "Fail"}
          />
        </Toolbar>
      </div>
    );
  };

  const filteredInspections = useMemo(() => {
    return submittedInspections.filter((inspection) => {
      const inspectionDetail = inspections.find(
        (detail) => detail.id === inspection.inspectionId
      );
      const matchesSearchTerm = searchTerm
        ? inspectionDetail?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          inspection.inspectionId.toString().includes(searchTerm.toLowerCase())
        : true;
      return matchesSearchTerm;
    });
  }, [submittedInspections, inspections, searchTerm]);

  return (
    <div className="inspection-home">
      {!showInspectionCreationView && !isSearchBarVisible && (
        <Toolbar>
          <Item
            location="before"
            widget="dxButton"
            options={backButtonOptions}
          />
          <Item location="center" locateInMenu="never" text={"Inspections"} />
          <Item
            location="after"
            locateInMenu="never"
            widget="dxButton"
            options={searchButtonOptions}
          />
          <Item
            location="after"
            locateInMenu="never"
            widget="dxButton"
            options={addButtonOptions}
          />
        </Toolbar>
      )}
      {isSearchBarVisible && (
        <TextBox
          placeholder="Search Inspections"
          showClearButton={true}
          value={searchTerm}
          onValueChanged={(e) => setSearchTerm(e.value)}
        >
          <TextBoxButton
            name="closeSearch"
            location="before"
            options={closeSearchButtonOptions}
          />
        </TextBox>
      )}
      {!showInspectionCreationView && (
        <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <List
            className="inspection-list"
            dataSource={filteredInspections}
            height="auto"
            itemRender={renderListItem}
          />
        </div>
      )}
      {showInspectionCreationView && (
        <InspectionForm
          onCancelInspection={closeInspectionCreationForm}
          onInspectionAdded={onInspectionAdded}
        ></InspectionForm>
      )}
    </div>
  );
}
