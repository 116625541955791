// JhaAttachmentsComponent.tsx
import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, TabPanel, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { ButtonItem, ButtonOptions, CustomRule, EmailRule, IButtonItemProps, Label, PatternRule, RequiredRule, SimpleItem } from 'devextreme-react/cjs/form';
import './job-email.scss';
import EmailActivitiesTab from './tabs/activities';
import EmailDetailsTab from './tabs/details';
import EmailColleaguesTab from './tabs/colleagues';
import { IJobCreationForm, TabItem } from '../../interfaces';
import { IJobEmail } from '../../interfaces/common/IJobEmail';
import { useAuth0 } from '@auth0/auth0-react';

export interface JobEmailProps {
    onCancel: () => void;
    onSend: (formDetails: IJobEmail) => void;
    initialForm?: IJobCreationForm;
}
const tabs: TabItem[] = [
    { id: 0, title: 'Activities', component: EmailActivitiesTab },
    { id: 1, title: 'Details', component: EmailDetailsTab },
    { id: 2, title: 'Colleagues', component: EmailColleaguesTab }
];
const JobEmail: React.FC<JobEmailProps> = ({ onCancel, onSend, initialForm }) => {
    const formRef = useRef<Form>(null);
    const {user} = useAuth0()
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [emailPayload, setEmailPayload] = useState<IJobEmail>({
        jobId: initialForm?.id || 0,
        to: '',
        from:user?.email ||'',
        formType: initialForm?.formType,
        subject: `Job Number/Work Order No - ${initialForm?.jobDetails.jobNumber || initialForm?.jobDetails.workOrderNumber} - ${initialForm?.formType} ${initialForm?.jobStatus}`
    });
    const handleComplettion = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            onSend(emailPayload);
        }
    };
    const completeButtonOptions = {
        text: 'Send',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleComplettion,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onCancel,
    };
    const handleSelectihandleFormChanged = (e: any) => {
        setSelectedTabIndex(e.component.option('selectedIndex'));
    };

    // Dynamically determine the ActiveComponent based on the selected tab index
    const item = () => {
        const SpecificComponent = tabs[selectedTabIndex].component;
        return <SpecificComponent initialForm={initialForm}/>;
    };
    return (
        <>
            <div className='step-form job-email'>
                <Toolbar className='form-header'>
                    <Item location="center"
                        locateInMenu="never"
                        text={`Email ${initialForm?.formType}`} />
                </Toolbar>
                <div className='form-fields' style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <Form
                        formData={emailPayload}
                        ref={formRef}
                        style={{ marginTop: '10px' }}
                    >
                        <SimpleItem dataField="to"
                            editorType="dxTextArea"
                            editorOptions={{
                                placeholder: "Please enter email addresses seperated by comma(,)",
                                showClearButton: true
                            }}>
                            <Label text="To" />
                            <RequiredRule />
                            <PatternRule type='pattern' pattern='^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$' message='Invalid Email' />
                        </SimpleItem>

                        <SimpleItem dataField="subject"
                            editorType="dxTextArea"
                            editorOptions={{
                                placeholder: "Type here...",
                                showClearButton: true,
                                disabled: true
                            }}>
                            <Label text="Subject" />

                        </SimpleItem>


                    </Form>
                    <TabPanel
                        key={tabs[selectedTabIndex].title}
                        className="dx-theme-background-color"
                        width={"100%"}
                        height={'100%'}
                        animationEnabled={true}
                        dataSource={tabs}
                        tabsPosition={'top'}
                        stylingMode={'primary'}
                        selectedIndex={selectedTabIndex}
                        onSelectionChanged={handleSelectihandleFormChanged}
                        itemRender={item}
                    />
                </div>
                <Toolbar className='form-buttons'>
                    <Item
                        widget='dxButton'
                        location='before'
                        options={cancelButtonOptions}

                    />
                    <Item
                        widget='dxButton'
                        location='after'
                        options={completeButtonOptions} />
                </Toolbar>
            </div>
        </>
    );
};

export default JobEmail;
