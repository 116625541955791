import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { IFile } from '../../../interfaces';
import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import './FileUploadComponent.scss'
import { imageUploadService } from '../../../api/common/ImageUploadService';
// Helper function to convert Blob to Base64
const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve((reader.result as string).split(',')[1]); // Type assertion
    };
    reader.readAsDataURL(blob);
});



interface ISafeFileUploaderProps {
    initialFileIds: string[];
    uploadedFileIds: (ids: string[]) => void;
}

const ISafeFileUploader: React.FC<ISafeFileUploaderProps> = ({ initialFileIds, uploadedFileIds }) => {
    const [files, setFiles] = useState<IFile[]>([]);
    const [fileIds, setFileIds] = useState<string[]>([...initialFileIds]);
    
    useEffect(() => {
        fileIds.forEach(async fileId => {
            const response = await imageUploadService.get(fileId);
            if(response.success)
                setFiles(prevFiles => [...prevFiles, response.data]);
        });
        uploadedFileIds(fileIds);
    }, [fileIds]);
      
    const downloadFile = (fileId: string) => {
        const file = files.find(f => f.id==fileId);
        if(!!file){
        const byteCharacters = atob(file.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' }); // Set the correct MIME type for the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        link.click();
        }
    };
      
    const onFileUpload = async (e: File, progressCallback: Function) => {
        const file = e; // Assuming single file upload for simplicity
        const base64 = await convertBlobToBase64(file);
        const newFile = {
            id: undefined, // Generate or retrieve an ID as needed
            name: file.name,
            content: base64 as string,
            createdDate: new Date(),
            type:e.type
        };
        const response = await imageUploadService.create(newFile as IFile);
        if(response.success){
            setFileIds([...fileIds, response.data.id ?? '']);
        }
       
    };

    const onFileDelete = (fileId: string) => {
        setFileIds(fileIds.filter(id => id !== fileId));
    };

    return (
        <div className='isafe-file-uploader'>
            <Toolbar>

                <Item
                    location='center'
                    render={() => <FileUploader
                        accept="image/*"
                        multiple={false}
                        selectButtonText="Upload Files"
                        uploadMode="instantly"
                        allowCanceling={true}
                        labelText=""
                        showFileList={false}
                        uploadFile={onFileUpload}
                        width={'100%'}
                    />}
                />
            </Toolbar>
            {fileIds.map((file, index) => (
                <Toolbar>
                    <Item
                        location='before'
                        render={() => <a onClick={() => downloadFile(file)} style={{ marginRight: "10px" }}>{files.find(f => f.id ==file)?.name}</a>
                    }

                    />
                    <Item
                        widget='dxButton'
                        location='after'
                        render={() => <Button icon='trash' onClick={() => onFileDelete(file || '')} />} />
                </Toolbar>
            ))}
        </div>
    );
};

export default ISafeFileUploader;
