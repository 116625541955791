import { IFile, IRegion } from '../../interfaces';
import { ApiService } from '../ApiService';



class ImageUploadService extends ApiService<IFile> {
  constructor() {
    super('Image');
  }

  // Additional methods specific to the User model can be added here
}

export const imageUploadService = new ImageUploadService();