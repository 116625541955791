import { useEffect, useState, ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { CONFIG_URL } from "./utils/constants";

interface Auth0ProviderProps {
  children: ReactNode;
}

interface Employee {
  domain: string;
  clientId: string;
  audience: string;
}

const Auth0ProviderConfig: React.FC<Auth0ProviderProps> = ({ children }) => {
  const [authSettings, setAuthSettings] = useState<Employee>();

  const fetchAuthSettings = async () => {
    try {
      const response = await fetch(CONFIG_URL);
      const { auth0 } = await response.json();
      setAuthSettings(auth0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAuthSettings();
  }, []);

  if (!(authSettings?.domain && authSettings?.clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={authSettings?.domain}
      clientId={authSettings?.clientId}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: authSettings?.audience,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderConfig;
