import { IApiResponse, IPreworkInspection, IInspectionCreationForm, InspectionFormData, IIncidentCreationForm } from '../../interfaces';
import { ApiService } from '../ApiService';
import axiosInstance from '../AxiosService';
import { AxiosResponse } from 'axios';


const endpoint=  'Incident'
class IncidentService extends ApiService<IIncidentCreationForm> {
  constructor() {
    super(endpoint);
  }
 
  // Additional methods specific to the User model can be added here
}

export const incidentService = new IncidentService();