// ColleagueSelectionComponent.tsx
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { Form, List, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../context/jha-form-context';

import { TextBox, Button as TextBoxButton, TextBoxTypes } from 'devextreme-react/text-box';
import { debounce } from 'lodash';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import { useLoad } from '../../../contexts';
import { IUser, IJhaColleague } from '../../../interfaces';
import { ISafeReadOnlyFieldWithTick } from '../../Customizatons';
import { erpService } from '../../../api';



const ColleagueSelectionComponent = () => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [userList, setUserList] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IJhaColleague[]>(formDetails.colleagues);
  const { showLoader } = useLoad();


  const fetchUsers = async (query: string) => {
    if (!query || query.length <3) return; // Optionally add a minimum length check before querying the API
    try {
      showLoader(true)
      const response = await erpService.getAllUsers(query);
      setUserList(response.data); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    finally {
      showLoader(false)
    }
  };
  const debounceFetchUsers = useCallback(
    debounce((term) => fetchUsers(term), 1000),
    []
  );

  const handleSelectUser = (user: IUser) => {
    const newColleague: IJhaColleague = {
      email: user.emailId,
      name: user.firstName +' '+user.lastName,
      isTrainedInFirstAid:false
    };

    const updatedColleagues = [...selectedUsers, newColleague];
    setSelectedUsers(updatedColleagues);
    handleFormChange('colleagues', updatedColleagues); // Update the formDetails

    setSearchTerm(''); // Clear the search box
    setUserList([]); // Optionally clear the user list
  };

  const handleRemoveUser = (user: IJhaColleague) => {
    if(user.isDefault)
      return;
    const updatedColleagues = selectedUsers.filter(u => u.email !== user.email);
    setSelectedUsers(updatedColleagues);
    handleFormChange('colleagues', updatedColleagues); // Update the formDetails
  };
  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      prevStep();
    },
  };
  const nextButtonOptions = {
    text: 'Next',
    type: 'default',
    stylingMode: 'contained',
    onClick: nextStep,
  };

  const previousButtonOptions = {
    text: 'Previous',
    type: 'default',
    stylingMode: 'outlined',
    onClick: prevStep,
  };
  const searchButton = useMemo<ButtonTypes.Properties>(
    () => ({
      icon: 'search',
      stylingMode: 'text'
    }),
    [],
  );
  const handleClearExternalColleagues = () => {
    handleFormChange('externalColleagues', '');
  };
  return (
    <div className='step-form colleague-selection-form'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'Add a Colleague'} />
      </Toolbar>
      <TextBox
        value={searchTerm}
        onValueChanged={(e) => {
          setSearchTerm(e.value);
        }}
        onKeyDown={(e: any) => {
          debounceFetchUsers(e.event.target.value);
        }}
        placeholder="Type a colleague's name / email"
        showClearButton={true}
      >
        <TextBoxButton
          name="search"
          location="before"
          options={searchButton}
        />
      </TextBox>
      {userList.length > 0 && (
        <List
          className='list-with-center-allignment'
          dataSource={userList}
          //height="40vw"
          displayExpr={'emailId'}
          selectByClick={true}
          onItemClick={({ itemData }) => itemData ? handleSelectUser(itemData) : ''}
        />
      )}
      {selectedUsers.length > 0 && (
        <List
          dataSource={selectedUsers}
          className='selected-users'
          displayExpr={'email'}
          itemRender={(colleague: IJhaColleague) => (
            <ISafeReadOnlyFieldWithTick
              value={colleague.name}
              description={colleague.email}
              onClick={() => handleRemoveUser(colleague)}
            />
          )}
        />
      )}
      <div className='form-external-colleagues'>
        <Form
          formData={formDetails}
        >
          <SimpleItem
            editorType="dxTextArea"
            dataField='externalColleagues'
            editorOptions={{
              placeholder: "Please note any external colleagues associated to this JHA",
              showClearButton: true,
              autoResizeEnabled: true,
              buttons: [{
                name: 'trash',
                location: 'after',
                options: {
                  stylingMode: 'text',
                  icon: 'trash',
                  onClick: handleClearExternalColleagues,
                },
              }],
            }}>
            <Label text="External Colleagues" />
          </SimpleItem>

        </Form>
        <Toolbar className='form-buttons-toolbar'>
          <Item
            widget='dxButton'
            location='before'
            options={previousButtonOptions}

          />
          <Item
            widget='dxButton'
            location='after'
            options={nextButtonOptions} />
        </Toolbar>
      </div>
    </div>
  );
};

export default ColleagueSelectionComponent;
