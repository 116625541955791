// MultiStepForm.tsx
import React from 'react';
import './jha-creation-multiview.scss';
import WorkOrderSelectionComponent from './forms/work-order-selection-form';
import ColleagueSelectionComponent from './forms/colleague-selection-form';
import ActivitiesSelectionComponent from './forms/activities-selection-form';
import SafetyComponent from './forms/safety-form';
import HazardsAndControlsComponent from './forms/hazards-controls-form';
import JobDetailsReviewComponent from './forms/job-details-review-form';
import JhaAttachmentsComponent from './forms/attachments-form';
import HseAcknowledgementComponent from './forms/hse-acknowledgement';
import ChaHazardAnalysisComponent from './forms/cha-hazard-analysis-form';
import ChaAcknowledgementComponent from './forms/cha-acknowledgement';
import { JhaFormProvider, useJhaFormContext } from './context/jha-form-context';
import { FormType } from '../../interfaces';


const MultiStepForm = () => {
  // The form state and functions are now provided through context
  const { step , formDetails} = useJhaFormContext();
  const jhaSteps = [
    <WorkOrderSelectionComponent />,
    <ColleagueSelectionComponent />,
    <ActivitiesSelectionComponent />,
    <SafetyComponent />,
    <HazardsAndControlsComponent />,
    <JobDetailsReviewComponent />,
    <JhaAttachmentsComponent />,
    <HseAcknowledgementComponent />,
    <ChaHazardAnalysisComponent />,
    <ChaAcknowledgementComponent />
  ];

  // Define step components for CHA
  const chaSteps = [
    <WorkOrderSelectionComponent />,
    <ColleagueSelectionComponent />,
    <ChaHazardAnalysisComponent />,
    <ChaAcknowledgementComponent />
  ];
  const steps = formDetails.formType === FormType.CHA ? chaSteps : jhaSteps;
  return (
    
      <div className='jha-cha-multistep-form'>
        {steps[step - 1]}
      </div>
  );
};

export default MultiStepForm;
