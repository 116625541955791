import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import './cha.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { List, Switch } from 'devextreme-react';
import { SwitchTypes } from 'devextreme-react/cjs/switch';
import { useLoad, useNavigation, useRegion, useToast } from '../../contexts';
import ConfirmationDialog from '../../dialogs/confirmation-dialog';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { ButtonTypes } from 'devextreme-react/cjs/button';
import { JhaFormProvider } from '../../components/jha-cha-multistep-form/context/jha-form-context';
import MultiStepForm from '../../components/jha-cha-multistep-form/jha-creation-multiview';
import { IJobCreationForm, FormType, JobStatus } from '../../interfaces';
import { jobService } from '../../api/common/JobService';
import JobEmail from '../../components/job-email-form/job-email';
import { IJobEmail } from '../../interfaces/common/IJobEmail';
enum ChaViews {
    Home,
    Creation,
    Email
}
export default function Cha() {
    const [isSearchBarVisible, setSearchBarVisible] = useState(false);
    const [currentView, setCurrentView] = useState(ChaViews.Home);
    const [searchTerm, setSearchTerm] = useState('');
    const { setShowHeader } = useNavigation();
    const { toggleRegionDialog } = useRegion();
    const [confirmationAction, setConfirmationAction] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const { showLoader } = useLoad();
    const { user } = useAuth0();
    const [submittedChas, setSubmittedChas] = useState<IJobCreationForm[]>([]);
    const { region } = useRegion();
    const { showSuccessToast,showErrorToast } = useToast();
    const [selectedJob, setSelectedJob] = useState<IJobCreationForm>();

    useEffect(() => {
        setShowHeader(currentView == ChaViews.Home);
    }, [currentView, setShowHeader]);

    const fetchSubmittedJobs = useCallback(async () => {
        showLoader(true);
        try {
            const request = {
                regionId: Number(region),
                userId: user?.email || '',
                formType: FormType.CHA
            };
            const response = await jobService.getSubmittedJobs(request);
            if (response.success) {
                const sortedJobs = response.data.sort((a, b) =>
                    new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
                );
                setSubmittedChas(sortedJobs);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader, region, user?.email]);

    useEffect(() => {
        fetchSubmittedJobs();
    }, [fetchSubmittedJobs]);

    const { logout } = useAuth0();

    const logoutButtonOptions = {
        icon: 'login',
        onClick: () => {
            setConfirmationMessage('Are you sure you want to Logout?');
            setConfirmationAction('logout');
        },
    };
    const regionDialogButtonOptions = {
        icon: 'globe',
        onClick: toggleRegionDialog
    };
    const addButtonOptions = {
        icon: 'plus',
        onClick: () => {
            setCurrentView(ChaViews.Creation);
        },
    };
    const closeSearchButtonOptions = useMemo<ButtonTypes.Properties>(
        () => ({
            icon: 'back',
            stylingMode: 'text',
            onClick: () => {
                setSearchBarVisible(false);
                setSearchTerm('');
            }
        }),
        [],
    );
    const searchButtonOptions = {
        icon: 'search',
        onClick: () => {
            setSearchBarVisible(!isSearchBarVisible); // Toggle visibility
        },
    };
    const onCancel = useCallback(() => {
        setConfirmationMessage('Are you sure you want to Discard the changes?');
        setConfirmationAction('cancelJhaCreation');
    }, []);

    const onCreateCha = useCallback(async (formDetails: IJobCreationForm) => {
        formDetails = {
            ...formDetails,
            jobStatus: JobStatus.COMPLETE
        }
        const response = await jobService.create(formDetails);
        if (response.success) {
            showSuccessToast('CHA Successfully Created!');
            fetchSubmittedJobs();
            setCurrentView(ChaViews.Home);
        }
    }, [fetchSubmittedJobs, showSuccessToast]);

    const onConfirm = useCallback(() => {
        if (confirmationAction === 'logout') {
            localStorage.clear();
            logout({
                logoutParams: { returnTo: window.location.origin }
              });
        } else if (confirmationAction === 'cancelJhaCreation') {
            setConfirmationAction('');
            setCurrentView(ChaViews.Home);
        }
        setConfirmationAction('');
    }, [confirmationAction, logout]);
    const onCancelEmail = useCallback(() => {
        setCurrentView(ChaViews.Home);
    }, []);
    const onSendEmail = useCallback(async (formDetails: IJobEmail) => {
       var response = await jobService.sendEmail(formDetails);
       if(response.success){
        setCurrentView(ChaViews.Home)
        showSuccessToast("Email Sent!");
        setCurrentView(ChaViews.Home);
       }
       else{
        showErrorToast("Error while Sending Email!")
       }
    }, [ showSuccessToast]);
    const renderListItem = (submittedJob: IJobCreationForm) => {
        return (
            <div className='card-container'>
                <Toolbar className='first-toolbar'>
                    <Item
                        location='before'
                        text={submittedJob?.jobDetails.siteName}
                    />
                    <Item
                        cssClass='item-value'
                        location='after'
                        text={submittedJob?.jobDetails.jobNumber || submittedJob?.jobDetails.workOrderNumber}
                    />
                </Toolbar>
                <Toolbar>
                    <Item
                        location='before'
                        render={() => <i className="dx-icon-event"></i>}
                    />
                    <Item
                        location='before'
                        text='Created On:'
                    />
                    <Item
                        location='after'
                        cssClass='item-value'
                        text={submittedJob?.createdDate ? new Date(submittedJob.createdDate + 'Z')?.toLocaleString() : new Date()?.toLocaleDateString()}
                    />
                </Toolbar>
                <Toolbar className='last-toolbar'>
                    <Item
                        location='center'
                        widget='dxButton'
                        options={{
                            icon: 'email',
                            text: 'Email',
                            onClick: () => {
                                setSelectedJob(submittedJob);
                                setCurrentView(ChaViews.Email)
                            },
                        }}
                    />
                </Toolbar>
            </div>
        );
    };

    const filteredChas = useMemo(() => {
        return submittedChas.filter((cha) => {
            const matchesSearchTerm = searchTerm ? cha.jobDetails.siteName?.toLowerCase().includes(searchTerm.toLowerCase()) || cha.jobDetails.jobNumber?.toLowerCase().includes(searchTerm.toLowerCase()) || cha.jobDetails.workOrderNumber?.toLowerCase().includes(searchTerm.toLowerCase()) : true;
            return matchesSearchTerm;
        });
    }, [submittedChas, searchTerm]);

    return (
        <div className='cha-home'>
            {(currentView == ChaViews.Home && !isSearchBarVisible) && (
                <Toolbar>
                    <Item location="before"
                        widget="dxButton"
                        options={logoutButtonOptions} />
                    <Item location="before"
                        widget="dxButton"
                        options={regionDialogButtonOptions} />
                    <Item location="center"
                        locateInMenu="auto"
                        text={'CHA'} />
                    <Item location="after"
                        locateInMenu="auto"
                        widget="dxButton"
                        options={searchButtonOptions} />
                    <Item location="after"
                        locateInMenu="auto"
                        widget="dxButton"
                        options={addButtonOptions} />
                </Toolbar>
            )}
            {isSearchBarVisible && (
                <TextBox
                    placeholder="Search CHAs"
                    showClearButton={true}
                    value={searchTerm}
                    onValueChanged={(e) => setSearchTerm(e.value)}
                >
                    <TextBoxButton
                        name="closeSearch"
                        location="before"
                        options={closeSearchButtonOptions}
                    />
                </TextBox>
            )}
            {currentView==ChaViews.Home && (
                <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <List
                        className="cha-list"
                        dataSource={filteredChas}
                        height="auto"
                        itemRender={renderListItem}
                    />
                </div>
            )}
            {currentView == ChaViews.Creation && (
                <JhaFormProvider onCancelJhaCreation={onCancel} onCreateJha={onCreateCha} formType={FormType.CHA}>
                    <MultiStepForm />
                </JhaFormProvider>
            )}
            {currentView === ChaViews.Email && !!selectedJob &&
                <JobEmail initialForm={selectedJob} onCancel={onCancelEmail} onSend={onSendEmail} />
            }
            <ConfirmationDialog
                visible={!!confirmationAction}
                title="Confirmation"
                message={confirmationMessage}
                onCancel={() => { setConfirmationAction(''); }}
                onConfirm={onConfirm}
                height={200}
            />
        </div>
    );
}
