import { useCallback, useEffect, useState } from "react";
import { CheckBox, List, LoadIndicator, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/cjs/toolbar";
import { useJhaFormContext } from "../context/jha-form-context";
import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form";
import { jobHazardsService } from "../../../api";
import { IJobHazard } from "../../../interfaces";
import "../jha-creation-multiview.scss";

const HazardsAndControlsComponent = () => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const [mandatoryJobHazards, setMandatoryJobHazards] = useState<IJobHazard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchJobHazards = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await jobHazardsService.getAllByRegion();
      if (response.success) {
        let activeItems = response.data?.filter((i) => i.isActive) || [];
        let mandatoryJobHazards = activeItems.filter((h) =>
            h.activities.some((a) => formDetails.activities.includes(a)) ||
            h.siteConditions.some((s) => formDetails.siteSpecificHazards.includes(s))
        );
        mandatoryJobHazards?.sort((a, b) => a.name.localeCompare(b.name));
        setMandatoryJobHazards(mandatoryJobHazards);

        if (!formDetails.jobHazardsAndControls.length) {
          const initialHazardsControls = mandatoryJobHazards.map((hazard) => ({
            hazardId: hazard.id,
            selectedControlMeasures: [],
            totalControlMeasures: hazard.controlMeasures.map((cm) => cm.id),
            additionalControlMeasures: "",
          }));
          handleFormChange("jobHazardsAndControls", initialHazardsControls);
        }
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [formDetails, handleFormChange]);

  useEffect(() => {
    fetchJobHazards();
  }, [fetchJobHazards]);

  const handleJobHazardsControlMeasures = (
    hazardId: number,
    field: "selectedControlMeasures" | "additionalControlMeasures",
    value: any,
    formDetails: any,
    handleFormChange: (field: string, value: any) => void
  ) => {
    const updatedHazardsAndControls = formDetails?.jobHazardsAndControls.map(
      (hazardControl: { hazardId: number; selectedControlMeasures: any[] }) => {
        if (hazardControl?.hazardId === hazardId) {
          if (field === "selectedControlMeasures") {
            const isSelected =
              hazardControl?.selectedControlMeasures.includes(value);
            return {
              ...hazardControl,
              selectedControlMeasures: isSelected
                ? hazardControl?.selectedControlMeasures.filter(
                    (id) => id !== value
                  )
                : [...hazardControl?.selectedControlMeasures, value],
            };
          } else if (field === "additionalControlMeasures") {
            return {
              ...hazardControl,
              additionalControlMeasures: value,
            };
          }
        }
        return hazardControl;
      }
    );
    handleFormChange("jobHazardsAndControls", updatedHazardsAndControls);
  };

  const backButtonOptions = {
    icon: "back",
    onClick: () => {
      prevStep();
    },
  };

  const nextButtonOptions = {
    text: "Next",
    type: "default",
    stylingMode: "contained",
    onClick: () => {
      nextStep();
    },
  };

  const previousButtonOptions = {
    text: "Previous",
    type: "default",
    stylingMode: "outlined",
    onClick: () => {
      prevStep();
    },
  };

  const getHazardStatus = (hazardId: number) => {
    const hazardControls = formDetails.jobHazardsAndControls.find((h) => h.hazardId === hazardId);
    if (!hazardControls) return "high";

    const total = hazardControls.totalControlMeasures.length;
    const selectedCount = hazardControls.selectedControlMeasures.length;
    const percentageSelected = (selectedCount / total) * 100;
    if (percentageSelected === 0) return "none";
    if (percentageSelected < 50) return "high";
    if (percentageSelected < 100) return "moderate";
    return "low";
  };

  return (
    <div className="step-form hazards-controls-form">
      <Toolbar className="form-header">
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item
          location="center"
          locateInMenu="never"
          text={"Job Hazards and Controls"}
        />
      </Toolbar>
      <div className="hazards-container">
        <div className="hazards-list">
          <Toolbar>
            <Item
              location="before"
              text="Job Hazards Based on Previous Selection"
            />
          </Toolbar>
        </div>

        <Form>
          {mandatoryJobHazards.map((hazard) => (
            <GroupItem key={hazard.id + "-" + hazard.name}>
              <GroupItem key={hazard.id}>
                <Toolbar className="hazard-status-toolbar">
                  <Item location="before" text={hazard.name} />
                  <Item
                    location="after"
                    render={() => {
                      const status = getHazardStatus(hazard.id);
                      return (
                        <div className="risk-status-container">
                          {[...Array(3)]?.map((_, index) => (
                            <div
                              className={`risk-status-indicator ${status}`}
                              key={index}
                            />
                          ))}
                        </div>
                      );
                    }}
                  />
                </Toolbar>
                <List
                  dataSource={hazard.controlMeasures}
                  height="auto"
                  showSelectionControls={false}
                  selectionMode="none"
                  itemRender={(data) => (
                    <CheckBox
                      text={data.name}
                      name={data.name}
                      value={
                        formDetails.jobHazardsAndControls
                          .find((h) => h.hazardId === hazard.id)
                          ?.selectedControlMeasures.includes(data.id) || false
                      }
                      onValueChanged={() =>
                        handleJobHazardsControlMeasures(
                          hazard.id,
                          "selectedControlMeasures",
                          data.id,
                          formDetails,
                          handleFormChange
                        )
                      }
                    />
                  )}
                />
              </GroupItem>
              <SimpleItem
                dataField={`jobHazardsAndControls[${formDetails.jobHazardsAndControls.findIndex(
                  (h) => h.hazardId === hazard.id
                )}].additionalControlMeasures`}
                editorOptions={{
                  placeholder: "Type here...",
                  showClearButton: true,
                  value: formDetails.jobHazardsAndControls.find(
                    (h) => h.hazardId === hazard.id
                  )?.additionalControlMeasures,
                  onValueChanged: (e: any) =>
                    handleJobHazardsControlMeasures(
                      hazard.id,
                      "additionalControlMeasures",
                      e.value,
                      formDetails,
                      handleFormChange
                    ),
                }}
                editorType="dxTextBox"
              >
                <Label text="Additional Control Measures" />
              </SimpleItem>
            </GroupItem>
          ))}
        </Form>

        <div className="hazard-loading-indicator">
          <LoadIndicator visible={isLoading} />
        </div>
      </div>
      <Toolbar className="form-buttons">
        <Item
          widget="dxButton"
          location="before"
          options={previousButtonOptions}
        />
        <Item widget="dxButton" location="after" options={nextButtonOptions} />
      </Toolbar>
    </div>
  );
};

export default HazardsAndControlsComponent;
