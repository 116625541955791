// JhaAttachmentsComponent.tsx
import React, { useCallback } from 'react';
import { Button } from 'devextreme-react/button';
import { Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../context/jha-form-context';
import ISafeFileUploader from '../../Customizatons/FileUploadComponent/FileUploadComponent';

const JhaAttachmentsComponent = () => {
  const { formDetails, handleFormChange, nextStep ,prevStep} = useJhaFormContext();

  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      prevStep();
    },
  };
  const nextButtonOptions = {
    text: 'Next',
    type: 'default',
    stylingMode: 'contained',
    onClick: nextStep,
};

const previousButtonOptions = {
    text: 'Previous',
    type: 'default',
    stylingMode: 'outlined',
    onClick: prevStep,
};
const uploadedFileIds = useCallback((ids : string[]) =>{
  handleFormChange('attachments',ids)
},[handleFormChange]);
  return (
    <div className='step-form'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'Attachments'} />
      </Toolbar>
      <ISafeFileUploader initialFileIds={[...formDetails?.attachments ||[]]} uploadedFileIds={uploadedFileIds} />
      <Toolbar className='form-buttons'>
        <Item 
          widget='dxButton'
          location='before'
          options={previousButtonOptions}
          
          />
          <Item 
          widget='dxButton'
          location='after'
          options={nextButtonOptions}/>
      </Toolbar>
    </div>
  );
};

export default JhaAttachmentsComponent;
