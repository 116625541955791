import { useCallback, useEffect, useRef, useState } from 'react';
import { Form, List, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../context/jha-form-context';
import { Label, SimpleItem } from 'devextreme-react/cjs/form';
import { RISK_STATUS } from '../../../utils/constants';

const JobDetailsReviewComponent = () => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const formRef = useRef<Form>(null);
  const [personsTrainedInFirstAid, setPersonsTrainedInFirstAid] = useState<string[]>(formDetails.colleagues.filter(c => c.isTrainedInFirstAid)?.map(colleague => colleague.email));
  const {high, medium, low} = RISK_STATUS

  useEffect(() => {
    handleFormChange('jobHazardsAndControls', formDetails.jobHazardsAndControls);
  }, [formDetails.jobHazardsAndControls]);

  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      prevStep();
    },
  };
  const nextButtonOptions = {
    text: 'Next',
    type: 'default',
    stylingMode: 'contained',
    onClick: nextStep,
  };

  const previousButtonOptions = {
    text: 'Previous',
    type: 'default',
    stylingMode: 'outlined',
    onClick: prevStep,
  };

  const getRiskOfJob = useCallback(() => {
    const hazards = formDetails.jobHazardsAndControls;
    const riskLevels = hazards.map((hazard) => {
      const selectedCount = hazard.selectedControlMeasures.length;
      const totalCount = hazard.totalControlMeasures.length;
      if (selectedCount === 0) return high;
      const percentageSelected = (selectedCount / totalCount) * 100;
      if (percentageSelected < 50) {
        return high;
      } else if (percentageSelected < 100) {
        return medium;
      } else {
        return low;
      }
    });
    if (riskLevels.includes(high)) {
      return high;
    } else if (riskLevels.includes(medium)) {
      return medium;
    } else {
      return low;
    }
  }, [formDetails]);

  const riskClass = getRiskOfJob().toLowerCase();
  
  const colleagues = [{
    key: "Person Trained in first Aid",
    items: formDetails.colleagues
  }]

  const GroupTemplate = useCallback((item: any) => {
    return "Person Trained in first Aid";
  }, []);
  
  const onSelectedItemKeysChange = useCallback((e: any) => {
    if (e.name === 'selectedItemKeys') {
      const newSelectedIds = e.value;
      if (newSelectedIds.sort().toString() !== personsTrainedInFirstAid.sort().toString()) {
        setPersonsTrainedInFirstAid(newSelectedIds);
      }
    }
  }, [personsTrainedInFirstAid, setPersonsTrainedInFirstAid]);

  useEffect(() => {
    const updatedColleagues = formDetails.colleagues.map(colleague => ({
      ...colleague,
      isTrainedInFirstAid: personsTrainedInFirstAid.includes(colleague.email)
    }));
    handleFormChange('colleagues', updatedColleagues);
  }, [personsTrainedInFirstAid]);

  return (
    <div className='step-form job-details-form'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'Job Details'} />
      </Toolbar>
      <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <Toolbar className='form-item'>
          <Item
            text='ID:'
            location='before'
          />
          <Item
            cssClass='item-value'
            text={formDetails.jobDetails.jobNumber || formDetails.jobDetails.workOrderNumber}
            location='after'
          />
        </Toolbar>
        <Toolbar className='form-item'>
          <Item
            text='Name:'
            location='before'
          />
          <Item
            cssClass='item-value'
            text={formDetails.jobDetails.siteName}
            location='after'
          />
        </Toolbar>
        <Toolbar className='form-item'>
          <Item
            text='CTC:'
            location='before'
          />
          <Item
            cssClass='item-value'
            text={formDetails.jobDetails.coveringCTC?.toString()}
            location='after'
          />
        </Toolbar>
        <Toolbar className='form-item'>
          <Item
            text='Risk:'
            location='before'
          />
          <Item
            cssClass={`item-value risk-${riskClass}`}
            text={getRiskOfJob()}
            location='after'
          />
        </Toolbar>
        <Form
          className='job-review-form'
          formData={formDetails}
          ref={formRef}
        >
          <SimpleItem dataField="permitNumber"
            editorOptions={{
              placeholder: "Type here...",
              showClearButton: true
            }}>
            <Label text="Permit #" />
            {/* <RequiredRule message="Site Name is mandatory" /> */}
          </SimpleItem>
          <SimpleItem dataField="musterPoint"
            editorOptions={{
              placeholder: "Type here...",
              showClearButton: true
            }}>
            <Label text="Muster Point" />
            {/* <RequiredRule message="Site Name is mandatory" /> */}
          </SimpleItem>
        </Form>
        <List
          dataSource={colleagues}
          grouped={true}
          keyExpr={'email'}
          displayExpr={'name'}
          showSelectionControls
          selectionMode='multiple'
          collapsibleGroups={true}
          groupTemplate={GroupTemplate}
          onOptionChanged={onSelectedItemKeysChange}
          selectedItemKeys={personsTrainedInFirstAid}
        />
      </div>
      <Toolbar className='form-buttons'>
        <Item
          widget='dxButton'
          location='before'
          options={previousButtonOptions}

        />
        <Item
          widget='dxButton'
          location='after'
          options={nextButtonOptions} />
      </Toolbar>
    </div>
  );
};

export default JobDetailsReviewComponent;
