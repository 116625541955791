import { Form, SelectBox } from "devextreme-react";
import { useJhaFormContext } from "../../context/jha-form-context";
import { SimpleItem, RequiredRule, Label, CustomRule } from 'devextreme-react/form';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import ListPickerDialog from "../../../../dialogs/list-picker-dialog";
import { selectionFormMethods, JobType, ICTCInfo } from "../../../../interfaces";
import { enumToArray } from "../../../../utils";
import { ISafeReadOnlyFieldWithArrow } from "../../../Customizatons";
import { erpService } from "../../../../api";
import { useLoad, useToast } from "../../../../contexts";

// You can define specific props if needed
const ManualSelectionForm = forwardRef<selectionFormMethods>((props, ref) => {
    const { formDetails, handleFormChange } = useJhaFormContext();
    const { showErrorToast } = useToast();
    const { showLoader } = useLoad();
    const formRef = useRef<Form>(null);
    useImperativeHandle(ref, () => ({
        validateForm: () => {
          const result = formRef.current?.instance.validate();
          return result?.isValid ?? false;
        },
      }));
    const [isJobTypeSelectionVisible, setIsJobTypeSelectionVisible] = useState<boolean>();
    const [ctcList, setCtcList] = useState<ICTCInfo[]>();
    const [selectedCtcs, setSelectedCtcs] = useState<string>();
    const jobTypeText = enumToArray(JobType).find(type => type.id === formDetails.jobDetails.type)?.name || 'Select Job Type';

    const handleJobTypeChange = useCallback((id: number) => {
        handleFormChange('type', id, 'jobDetails');
        setIsJobTypeSelectionVisible(false);
    }, []);

    const fetchCTCList = useCallback(async () => {
        showLoader(true);
        try {
            const response = await erpService.getAllCTCs();
            if (response?.success) {
                setCtcList(response?.data);
            } else {
                showErrorToast('Error fetching CTCs');
            }
        } catch (error) {
            showErrorToast('Network or Server error');
        } finally {
            showLoader(false);
        }
    }, []);

    useEffect(() => {
        fetchCTCList();
    }, [fetchCTCList]);

    const onSelectedCtcChange = useCallback((e:any) => {
          setSelectedCtcs(e.value);
          handleFormChange('coveringCTC', e.value, 'jobDetails');
      }, [setSelectedCtcs, handleFormChange]);

    return (
        <Form
            formData={formDetails.jobDetails}
            ref={formRef}
        >
            <SimpleItem
                render={() => (
                    <ISafeReadOnlyFieldWithArrow
                        value={jobTypeText}
                        onClick={() => setIsJobTypeSelectionVisible(true)}
                    />
                )}
            />

            {formDetails.jobDetails.type === JobType["Job#"] && <SimpleItem dataField="jobNumber"
                editorOptions={{
                    placeholder: "Type here...",
                    showClearButton: true
                }}>
                <Label text="Job#" />
                <CustomRule
                    message="Job # is mandatory"
                    validationCallback={e => {
                        // Conditionally require jobNumber based on jobType
                        if (formDetails.jobDetails.type === JobType["Job#"]) {
                            return !!e.value; // Ensure jobNumber is not empty
                        }
                        return true; // No validation error if condition is not met
                    }}
                />
            </SimpleItem>}
            {formDetails.jobDetails.type === JobType.WorkOrder && <SimpleItem dataField="workOrderNumber"
                editorOptions={{
                    placeholder: "Type here...",
                    showClearButton: true
                }}>
                <Label text="Work Order#" />
                <CustomRule
                    message="Work Order # is mandatory"
                    validationCallback={e => {
                        // Conditionally require jobNumber based on jobType
                        if (formDetails.jobDetails.type === JobType.WorkOrder) {
                            return !!e.value; // Ensure jobNumber is not empty
                        }
                        return true; // No validation error if condition is not met
                    }}
                />
            </SimpleItem>}
            <SimpleItem dataField="siteName"
                editorOptions={{
                    placeholder: "Type here...",
                    showClearButton: true
                }}>
                <Label text="Site Name" />
                <RequiredRule message="Site Name is mandatory" />
            </SimpleItem>
            <SimpleItem dataField="coveringCTC">
                <Label text="Covering CTC" />
                <RequiredRule message="CTC is mandatory" />
                <SelectBox
                    dataSource={ctcList}
                    valueExpr="ctc"
                    displayExpr="name"
                    value={selectedCtcs}
                    placeholder="Select CTC"
                    width="100%"
                    searchEnabled={true}
                    onValueChanged={onSelectedCtcChange}
                    showClearButton={true}
                    dropDownOptions={{
                        width: '80vw',
                        height: '30vh'
                    }}
                />
            </SimpleItem>
            {isJobTypeSelectionVisible && (
                <ListPickerDialog
                    visible={isJobTypeSelectionVisible}
                    title="Select"
                    onCancel={() => setIsJobTypeSelectionVisible(false)}
                    onSelected={(id: number) => handleJobTypeChange(id)}
                    options={enumToArray(JobType)}
                    height={200}
                    defaultValue={formDetails.jobDetails.type}
                />
            )}
        </Form>

    )
})
export default ManualSelectionForm;
