// HseAcknowledgementComponent.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { CheckBox, Form, List, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../context/jha-form-context';
import { GroupItem } from 'devextreme-react/cjs/form';
import { hseAcknowledgementService } from '../../../api';
import { useLoad } from '../../../contexts';
import { IHseAcknowledgement } from '../../../interfaces';
const HseAcknowledgementComponent = () => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const { showLoader } = useLoad();
  const [hseAcknowledgements, setHseAcknowledgements] = useState<IHseAcknowledgement[]>([]);

  const fetchHseAcknowledgements = useCallback(async () => {
    showLoader(true);
    try {
      const response = await hseAcknowledgementService.getAllByRegion();
      if (response.success) {
        var activeItems = response.data?.filter(i => i.isActive);
        setHseAcknowledgements(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  useEffect(() => {
    fetchHseAcknowledgements();
  }, []);
  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      prevStep();
    },
  };
  const nextButtonOptions = {
    text: 'Confirm',
    type: 'default',
    stylingMode: 'contained',
    onClick: nextStep,
  };

  const previousButtonOptions = {
    text: 'Previous',
    type: 'default',
    stylingMode: 'outlined',
    onClick: prevStep,
  };
  const handleSelectionChange = (id: number) => {
    let updatedAcknowledgements;
    if (formDetails.hseAcknowledgements.includes(id)) {
      // If ID is already present, remove it (deselect)
      updatedAcknowledgements = formDetails.hseAcknowledgements.filter(ackId => ackId !== id);
    } else {
      // If ID is not present, add it (select)
      updatedAcknowledgements = [...formDetails.hseAcknowledgements, id];
    }

    // Update the form state with the new list of selected acknowledgements
    handleFormChange('hseAcknowledgements', updatedAcknowledgements);
  }
  return (
    <div className='step-form hse-ack-form'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'HSE Acknowledgement'} />
      </Toolbar>
      <Form>
        <GroupItem key={'hse-acknowledgement'} >
          <Toolbar>
            <Item location='before'
            text='*'
            cssClass='mandatory-icon'/>
            <Item location='before'
            text='I certify that I have'
            cssClass='certify-text'/>
          </Toolbar>
          <List
            dataSource={hseAcknowledgements}
            height="auto"
            showSelectionControls={false}
            selectionMode="none"
            itemRender={(data: IHseAcknowledgement) => (
              <CheckBox
                text={data.name}
                name={data.name}
                value={!!formDetails.hseAcknowledgements.find(a => a == data.id)}
                onValueChanged={() => handleSelectionChange(data.id)}
              />
            )}
          />
        </GroupItem>
      </Form>
      <Toolbar className='form-buttons'>
        <Item
          widget='dxButton'
          location='before'
          options={previousButtonOptions}

        />
        <Item
          widget='dxButton'
          location='after'
          disabled={formDetails.hseAcknowledgements.length < hseAcknowledgements.length}
          options={nextButtonOptions} />
      </Toolbar>
    </div>
  );
};

export default HseAcknowledgementComponent;
