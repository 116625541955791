import { ICyberHazard } from '../../interfaces';
import { ApiService } from '../ApiService';



class CyberHazardService extends ApiService<ICyberHazard> {
  constructor() {
    super('CyberHazard');
  }

  // Additional methods specific to the User model can be added here
}

export const cyberHazardsService = new CyberHazardService();