import { IPpe } from '../../interfaces'
import { ApiService } from '../ApiService';



class PpeService extends ApiService<IPpe> {
  constructor() {
    super('PPE');
  }

  // Additional methods specific to the User model can be added here
}

export const ppeService = new PpeService();