import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { useEffect, useCallback } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import { licenseKey } from './devextreme-license';
import config from 'devextreme/core/config';
import ErrorBoundary from './utils/error-boundary';
import { NavigationProvider, LoadProvider, ToastProvider, RegionProvider } from './contexts';
import Auth0ProviderConfig from './Auth0ProviderConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadPanel } from 'devextreme-react';
import { updateTokenProvider } from './api';
import { CONFIG_URL } from './utils/constants';

config({
  licenseKey
})
function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const getApiBaseUrl = useCallback(async () => {
    try {
      const response = await fetch(CONFIG_URL);
      const { api } = await response.json();
      window.localStorage.setItem('iSafe2BaseUrl', api?.iSafe2BaseUrl);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    getApiBaseUrl();
    // Update the token provider with the actual implementation
    updateTokenProvider(() => getAccessTokenSilently().catch((error) => {
      console.error("Failed to get access token silently:", error);
      return null;
    }));
  }, [getAccessTokenSilently, getApiBaseUrl]);
  


  // Ensure components load only when authenticated and token is ready
  if (isLoading ) {
    return <LoadPanel
      shadingColor="rgba(0,0,0,0.4)"
      position={'center'}
      visible={true}
      showIndicator={true}
    />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return<></>;
  }

  console.log(user); // Log user information if needed
  return <Content />;
}


export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Auth0ProviderConfig>
      <Router>
        <NavigationProvider>
          <ErrorBoundary fallback={<h1>Sorry, there was an error.</h1>}>
            <LoadProvider>
              <ToastProvider>
                <RegionProvider>
                  <div className={`app ${screenSizeClass}`}>
                    <App />
                  </div>
                </RegionProvider>
              </ToastProvider>
            </LoadProvider>
          </ErrorBoundary>
        </NavigationProvider>
      </Router>
    </Auth0ProviderConfig>
  );
}
