import { useCallback, useEffect, useMemo, useState } from 'react';
import { List, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { ButtonTypes } from 'devextreme-react/cjs/button';
import { useLoad, useNavigation } from '../../../contexts';
import { IPreworkInspection, IInspectionCreationForm, InspectionFormData } from '../../../interfaces';
import { inspectionService } from '../../../api';
import ReadOnlyFieldWithArrow from '../../../components/Customizatons/CustomFIelds/readonly-field-with-arrow';
import DynamicForm from '../../../components/inspection-form/inspection-detail-form';
interface InspectionIdPair {
  inspectionId: number;
  previousSubmittedForm:IInspectionCreationForm|null;
  submittedId: number | null; // `null` until the submission is successful
}
export const InspectionForm: React.FC<{onCancelInspection: () => void; onInspectionAdded: (id:number) => void; completedInspections?:number[] }> = ({ onInspectionAdded,onCancelInspection,completedInspections }) => {
  const { showLoader } = useLoad();
  const [inspections, setInspections] = useState<IPreworkInspection[]>([]);
  const [inspectionFormDetails, setInspectionFormDetails] = useState<InspectionFormData | null>(null);
  const [showFormDetails, setShowFormDetails] = useState<boolean>(false);
  const [selectedInspectionIds, setSelectedInspectionIds] = useState<InspectionIdPair[]>([]);

  const fetchInspections = useCallback(async () => {
    showLoader(true); 
    try {
      const response = await inspectionService.getAllByRegion();
      if (response.success) {
        let activeItems = response.data?.filter(i => i.isActive) || [];
        activeItems?.sort((a, b) => a.name.localeCompare(b.name));
        setInspections(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);


  const fetchInspectionFormDetails = useCallback(async (inspectionId:number) => {
    showLoader(true);
    try {
      const response = await inspectionService.getFormDetails(inspectionId);
      if (response.success) {
        response.data.id = inspectionId;
        setInspectionFormDetails(response.data);
        setShowFormDetails(true);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  useEffect(() => {
    fetchInspections();
  }, []);
  useEffect(() => {
    // Assuming preworkInspectionDetails are loaded into formDetails
    const  preworkInspectionDetails  = completedInspections;

    preworkInspectionDetails?.forEach(async (submittedId) => {
        const response = await inspectionService.getInspectionSubmittedForm(submittedId);  // Assuming this API call exists
        if (response.success) {
            const inspectionId = response.data.inspectionId; 
            setSelectedInspectionIds(prev => [
              ...prev.filter(item => item.inspectionId !== inspectionId),
               { inspectionId, submittedId,previousSubmittedForm:response.data }]);
        }
    });
}, [completedInspections, setSelectedInspectionIds]);
  const renderItem = useCallback((data: IPreworkInspection) => {
    return (
      <div className={`list-item`}>
        <ReadOnlyFieldWithArrow displayCheck={selectedInspectionIds?.some(i => i.inspectionId ===data.id)} value={data.name} onClick={() => console.log('tst')} />
      </div>
    );
  }, [selectedInspectionIds]);
  
  const onSelectedItemKeysChange = useCallback((e: any) => {
    const currentId = e.itemData?.id;
    if (currentId > 0) {
        fetchInspectionFormDetails(currentId);
    }
}, []);

  const onSubmitInspection = useCallback(async (data: IInspectionCreationForm) => {
    showLoader(true);
    try {
    const response = await inspectionService.submitInspectionForm(data);
    if (response.success) {
        const submittedId = response.data.id;  // Assuming the API returns the id as response.data.id
        // Get current inspection IDs without the one that's being updated
        setShowFormDetails(false);
        if(submittedId) onInspectionAdded(submittedId);
    }}
    catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
}, [setShowFormDetails]);


  const onCancelInspectionForm = useCallback(() => {
    setShowFormDetails(false);
  }, [setShowFormDetails]);
  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      onCancelInspection();
    },
  };
    return (
      <div className='step-form'>
      {!showFormDetails && <Toolbar className='form-header'>
          <Item location="before"
            widget="dxButton"
            options={backButtonOptions} />
  
          <Item location="center"
            locateInMenu="never"
            text={'Safety'} />
        </Toolbar>}
      {!showFormDetails ? <List
        dataSource={inspections}
        displayExpr="name"
        keyExpr="id"
        itemRender={renderItem}
        selectionMode="none"
        selectByClick={true}
        searchExpr="name"
        onItemClick={onSelectedItemKeysChange}
      >
      </List>:(inspectionFormDetails && <DynamicForm formData={inspectionFormDetails} onCancel={onCancelInspectionForm} initialValues={selectedInspectionIds?.find(i => i.inspectionId ==inspectionFormDetails.id)?.previousSubmittedForm}  submitForm={onSubmitInspection}/>)
      }

    </div>
    
  );
};
