import { TextArea, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/cjs/toolbar";
import { useState, useCallback, useEffect, useMemo } from "react";
import { siteConditionService, ppeService, inspectionService } from "../../../api";
import { useLoad } from "../../../contexts";
import { IEmailTabPanelProps, IPreworkInspection, ISiteCondition } from "../../../interfaces";



const EmailDetailsTab: React.FC<IEmailTabPanelProps> = ({ initialForm }) => {
    const [ppeText, setPpeText] = useState<string>('');
    const [inspections, setInspections] = useState<IPreworkInspection[]>([]);
    const [selectedInspections, setSelectedInspections] = useState<number[]>([]);
    const { showLoader } = useLoad();
    const [siteConditions, setSiteConditions] = useState<ISiteCondition[]>([]);
    const fetchSiteConditions = useCallback(async () => {
        showLoader(true);
        try {
            const response = await siteConditionService.getAllByRegion();
            if (response.success) {
                let activeItems = response.data?.filter(i => i.isActive) || [];
                const noneItem = { id: -1, name: 'None', isActive: true, createdDate: new Date(), regionId: -1, jobHazards: [], preworkInspections: [] };
                activeItems?.sort((a, b) => a.name.localeCompare(b.name));
                // Add the 'None' item to the beginning of the activeItems array
                activeItems = [noneItem, ...activeItems];
                setSiteConditions(activeItems);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, []);
    const fetchPpes = useCallback(async () => {
        showLoader(true);
        try {
            const response = await ppeService.getAllByRegion();
            if (response.success) {
                const names: string[] = []
                initialForm.personalProtectiveEquipment.forEach(ppe => {
                    names.push(response.data.find(pp => pp.id === ppe)?.name || '')
                });
                setPpeText(names.join(', '))
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, [initialForm, setPpeText]);
    const fetchInspections = useCallback(async () => {
        showLoader(true);
        try {
            const response = await inspectionService.getAllByRegion();
            if (response.success) {
                let activeItems = response.data?.filter(i => i.isActive) || [];
                const noneItem = { id: -1, name: 'None', isActive: true, createdDate: new Date(), regionId: -1 };
                activeItems?.sort((a, b) => a.name.localeCompare(b.name));
                // Add the 'None' item to the beginning of the activeItems array
                activeItems = [noneItem, ...activeItems];
                setInspections(activeItems);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, []);
    useEffect(() => {
        // Assuming preworkInspectionDetails are loaded into formDetails
        const { preworkInspectionDetails } = initialForm;
        const list: number[] = []
        preworkInspectionDetails.forEach(async (submittedId) => {
            const response = await inspectionService.getInspectionSubmittedForm(submittedId);  // Assuming this API call exists
            if (response.success) {
                const inspectionId = response.data.inspectionId;
                list.push(inspectionId)
            }
        });
        setSelectedInspections(list)
    }, [initialForm, setSelectedInspections]);
    useEffect(() => {
        fetchPpes();
        fetchInspections();
        fetchSiteConditions();
    }, []);

    return <div className="email-details-tab">
        <Toolbar>
            <Item
                location="before"
                showText="always"
                cssClass="bold-toolbar"
                text={'Form Details'}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Date:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm?.createdDate ? new Date(initialForm.createdDate + 'Z')?.toLocaleString() : new Date()?.toLocaleDateString()}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Job or Work Order #:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm.jobDetails.jobNumber || initialForm.jobDetails.workOrderNumber}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Completed By:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm.colleagues[0]?.name || 'N/A'}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Location:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm.jobDetails.siteName}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Permit Number:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm.permitNumber}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Muster Point:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm.permitNumber}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                text={'Person Trained in First Aid:'}
            />
            <Item
                location="after"
                showText="always"
                cssClass="item-value"
                text={initialForm.colleagues.filter(c => c.isTrainedInFirstAid)?.map(user => user.name)?.join(', ') || 'N/A'}
            />
        </Toolbar>
        <Toolbar>
            <Item
                location="before"
                showText="always"
                cssClass="bold-toolbar"
                text={'Site Details'}
            />
        </Toolbar>
        <TextArea
            height={'fit-content'}
            label="Pre-Work Inspection Details:"
            value={inspections.filter(i => selectedInspections.some(s => s == i.id))?.map(i => i.name)?.join(', ') || 'N/A'}
            disabled
        />
        <TextArea
height={'fit-content'}
            value={ppeText || 'N/A'}
            label="PPE Details:"
            disabled
        />

        <TextArea
height={'fit-content'}
            value={siteConditions.filter(i => initialForm.siteSpecificHazards.some(p => p == i.id))?.map(i => i.name)?.join(', ') || 'N/A'}
            label="Work Area Details:"
            disabled
        />
    </div>
}
export default EmailDetailsTab;