import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { ISpectrumJob, selectionFormMethods, ID65WorkOrder } from "../../../../interfaces";
import { erpService } from "../../../../api";
import { useLoad, useToast } from "../../../../contexts";
import { List, TextBox } from "devextreme-react";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useJhaFormContext } from "../../context/jha-form-context";

const D365SelectionForm = forwardRef<selectionFormMethods>((props, ref) => {
    const { formDetails, handleFormChange } = useJhaFormContext();
    const { showLoader } = useLoad();
    const { showErrorToast } = useToast();
    const [d365Wo, setD365Wo] = useState<ID65WorkOrder[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [noDataText, setNoDataText] = useState('Please search with ID');
    useImperativeHandle(ref, () => ({
        validateForm: () => {
          return !!formDetails.jobDetails.workOrderNumber;
        },
      }));

    const fetchD365Wo = useCallback(async (jobId:string) => {
        if (jobId?.length < 3) return;
        try {
            setNoDataText('Searching...')
            showLoader(true);
            const response = await erpService.getD65WorkOrders(jobId);
            if (response.success) {
                setD365Wo(response.data);
            }

        } catch (e) {
            console.log(e);
            showErrorToast("An error occurred while fetching D365 Work Orders");
        }
        finally {
            showLoader(false);
            setNoDataText('No Data Found!')
        }

    }, []);
    useEffect(() => {
        if(!!formDetails.jobDetails?.workOrderNumber)
            fetchD365Wo(formDetails.jobDetails?.workOrderNumber)
    }, []);
    const renderListItem = useCallback((job: ID65WorkOrder) => {
        return (
            <div className='card-container'>
                <Toolbar className='first-toolbar'>
                    <Item
                        location='before'
                        text={`WO #: ${job?.workOrderId}`}
                    />
                    <Item
                        location='after'
                        text={`CTC: ${job?.ctc}`}
                    />
                </Toolbar>
                <Toolbar>
                    <Item
                        location='before'
                        render={() => <i className="dx-icon-globe"></i>}
                    />
                    <Item
                        location='before'
                        text={job.serviceAccount.name}
                    />
                </Toolbar>
                <Toolbar className='last-toolbar'>
                    <Item
                        location='center'
                        widget='dxButton'
                        options={{
                            icon: formDetails.jobDetails?.workOrderNumber === job.workOrderId ?'check':'plus',
                            text: formDetails.jobDetails?.workOrderNumber === job.workOrderId ?'Selected':'Select',
                            type: 'default',
                            disabled: formDetails.jobDetails?.workOrderNumber === job.workOrderId,
                            onClick: () => {
                                handleFormChange('workOrderNumber', job?.workOrderId ?? '', 'jobDetails');
                                handleFormChange('coveringCTC', job?.ctc ?? '', 'jobDetails');
                                handleFormChange('siteName', job?.serviceAccount.name ?? '', 'jobDetails');
                            },
                        }}
                    />
                </Toolbar>
            </div>
        );
    },[formDetails]);

    return <div className="spectrum-selection-form">
        <TextBox
            placeholder="Enter ID..."
            showClearButton={true}
            value={searchTerm}
            onValueChanged={(e) => setSearchTerm(e.value)}
            onKeyDown={(e: any) => {
                if (e.event.key === 'Enter') {
                    fetchD365Wo(e.event.target.value);
                  }
            }}
            buttons={[
                {
                    name: 'search',
                    location: 'before',
                    
                    options: {
                        icon: 'search',
                        type: 'default'

                    }
                }
            ]}
        ></TextBox>
        <div className="spectrum-joblist-container">
            <List
                className="spectrum-job-list"
                dataSource={d365Wo}
                height="auto"
                itemRender={renderListItem}
                noDataText={noDataText}

            />
        </div>
    </div>
})
export default D365SelectionForm;