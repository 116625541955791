// JhaAttachmentsComponent.tsx
import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, SelectBox, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { IIncidentCreationForm, IJhaColleague, IJobCreationForm, IPostWorkReview, IUser, IncidentStatus, IncidentType } from '../../../../interfaces';
import { ButtonItem, ButtonOptions, CustomRule, IButtonItemProps, Label, RequiredRule, SimpleItem } from 'devextreme-react/cjs/form';
import './vehicle-accident-report.scss';
import { erpService } from '../../../../api';
import { debounce } from 'lodash';
import { useLoad } from '../../../../contexts';

export interface VehicleAccidentReportProps {
    onCancel: () => void;
    onSubmit: (formDetails: IIncidentCreationForm) => void;
}

const VehicleAccidentReport: React.FC<VehicleAccidentReportProps> = ({ onCancel,onSubmit }) => {
    const formRef = useRef<Form>(null);
    const { showLoader } = useLoad();

    const [completionPayload, setCompletionPayload] = useState<IIncidentCreationForm>({
        id:0,
        createdDate:new Date(),
        description:'',
        images:[],
        incidentDate:new Date(),
        incidentType:IncidentType.VEHICLE,
        isJhaCompleted:false,
        jobId:0,
        jobNumber:'',
        regionId: Number(localStorage.getItem('region')) || 0,
        ctc:'',
        incidentStatus:IncidentStatus.REPORTED,
        vehicleIncident:{
            associatedConvergentColleague:[],
            claimant:'',
            gbLossReference:''
        }

    });

    const [colleagues, setColleagues] = useState<IUser[]>([]);
    const debounceFetchUsers = useCallback(
        debounce((term) => handleSearch(term), 1000),
        []
    );
    const handleSearch = useCallback(async (e: any) => {
        try {
            const value = e.component.option('text');
            if (value.length >= 3) {
                showLoader(true)
                const response = await erpService.getAllUsers(value); //modify thsi based on the users API
                setColleagues(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }

    }, []);

    const handleSelectionChange = useCallback((e: any) => {
        const selectedColleague = e.selectedItem as IUser;
        console.log(e)
        if (!!completionPayload.vehicleIncident)
            setCompletionPayload({
                ...completionPayload,
                vehicleIncident: {
                    ...completionPayload.vehicleIncident,
                    associatedConvergentColleague: selectedColleague ? [{
                        name:selectedColleague.firstName +' '+selectedColleague.lastName,
                        email:selectedColleague.emailId,
                        isTrainedInFirstAid:false
                    }] : []
                }
            });
    }, [completionPayload]);
    const handleComplettion = useCallback(() => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var formattedPayload = {
                ...completionPayload,
                isJhaCompleted : !!completionPayload.isJhaCompleted
            }
            onSubmit(formattedPayload);
        }
    },[completionPayload]);
    const completeButtonOptions = {
        text: 'Submit',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleComplettion,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onCancel,
    };
    const backButtonOptions = {
        icon: 'back',
        onClick: onCancel
    };
    return (
        <div className='step-form vehicle-accident-report'>
            <Toolbar className='form-header'>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    locateInMenu="never"
                    text={'Create Vehicle Accident Report'} />
            </Toolbar>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <Form
                    formData={completionPayload}
                    ref={formRef}
                    style={{ marginTop: '10px' }}
                >
                    
                    <SimpleItem dataField="jobNumber"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Job/Work Order Number" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="vehicleIncident.gbLossReference"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="GB Loss Reference #" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="vehicleIncident.claimant"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Claimant" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem>
                        <Label text="Associated Convergint Colleague" />
                        <SelectBox
                            dataSource={colleagues}
                            //value={completionPayload.generalIncident?.affectedColleagues[0]?.email || ''}
                            searchEnabled={true}
                            valueExpr="emailId"
                            displayExpr={(item) => item ? `${item.firstName} ${item.lastName}` : ''}
                            searchTimeout={5000}
                            minSearchLength={3}
                            showClearButton={true}
                            onInput={debounceFetchUsers}
                            onSelectionChanged={handleSelectionChange}
                            placeholder="Search for a colleague..."
                        />
                    </SimpleItem>
                    <SimpleItem dataField="incidentDate"
                        editorType="dxDateBox"
                        >
                        <Label text="Accident Date" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem 
                     dataField="ctc"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="CTC" />
                        <RequiredRule />
                    </SimpleItem>
                    
                    <SimpleItem 
                     dataField="description"
                        editorType="dxTextArea"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Description" />
                        <RequiredRule />
                    </SimpleItem>
                    

                </Form>
            </div>
            <Toolbar className='form-buttons'>
                <Item
                    widget='dxButton'
                    location='before'
                    options={cancelButtonOptions}

                />
                <Item
                    widget='dxButton'
                    location='after'
                    options={completeButtonOptions} />
            </Toolbar>
        </div>
    );
};

export default VehicleAccidentReport;
