// ApiService.ts
import { AxiosResponse } from 'axios';
import axiosInstance from './AxiosService';
import { IApiResponse } from '../interfaces';

export class ApiService<T> {
  constructor(private endpoint: string) {}

  getAllByRegion(params = {}): Promise<IApiResponse<T[]>> {
    return axiosInstance.get(`${this.endpoint}?regionId=${localStorage.getItem('region')}&isActive=true`, { params })
      .then((response: AxiosResponse) => this.handleArrayResponse(response));
  }
  getAll(params = {}): Promise<IApiResponse<T[]>> {
    return axiosInstance.get(`${this.endpoint}`, { params })
      .then((response: AxiosResponse) => this.handleArrayResponse(response));
  }
  get(id: string): Promise<IApiResponse<T>> {
    return axiosInstance.get(`${this.endpoint}/${id}`)
      .then((response: AxiosResponse) => this.handleResponse(response));
  }

  create(data: T): Promise<IApiResponse<T>> {
    return axiosInstance.post(`${this.endpoint}`, data)
      .then((response: AxiosResponse) => this.handleResponse(response));
  }

  update(id: string, data: T): Promise<IApiResponse<T>> {
    return axiosInstance.put(`${this.endpoint}/${id}`, data)
      .then((response: AxiosResponse) => this.handleResponse(response));
  }

  delete(id: string): Promise<IApiResponse<T>> {
    return axiosInstance.delete(`${this.endpoint}/${id}`)
      .then((response: AxiosResponse) => this.handleResponse(response));
  }
  activate(id: string, isActive: boolean): Promise<IApiResponse<T>> {
    return axiosInstance.post(`${this.endpoint}/${id}/activate?isActive=${isActive}`)
      .then((response: AxiosResponse) => this.handleResponse(response));
  }

  private handleResponse(response: AxiosResponse<IApiResponse<T>>): IApiResponse<T> {
    return response.data;
  }

  private handleArrayResponse(response: AxiosResponse<IApiResponse<T[]>>): IApiResponse<T[]> {
    return response.data;
  }
}
