import React, { useState, useCallback, useEffect } from "react";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import List from 'devextreme-react/list';
import { activityService } from "../../../api";
import { useLoad, useToast } from "../../../contexts";
import { IEmailTabPanelProps, IActivity } from "../../../interfaces";

const EmailActivitiesTab: React.FC<IEmailTabPanelProps> = ({ initialForm }) => {
    console.log(initialForm)
    const [activities, setActivities] = useState<IActivity[]>([]);
    const { showLoader } = useLoad();
    const { showErrorToast, showSuccessToast } = useToast();

    const fetchActivities = useCallback(async () => {
        showLoader(true);
        try {
            const response = await activityService.getAllByRegion();
            if (response.success) {
                const activeItems = response.data?.filter(i => i.isActive);
                activeItems?.sort((a, b) => a.name.localeCompare(b.name));
                setActivities(activeItems);
            } else {
                console.error(response.errors);
                showErrorToast('Error fetching activities');
            }
        } catch (error) {
            console.error(error);
            showErrorToast('Network or server error');
        } finally {
            showLoader(false);
        }
    }, [showLoader, showErrorToast]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    const renderActivityItem = (activity: IActivity) => {
        return (
            <div className="email-tab-item">
                <Toolbar>
                    <Item location="before" showText="always" text={activity.name} />
                </Toolbar>
            </div>
        );
    };

    return (
        <div className="email-activities-tab">
            <List
                //dataSource ={activities}
                dataSource={activities.filter(a => initialForm?.activities?.some(i => i ==a.id))}
                height="auto"
                itemRender={renderActivityItem}
            />
        </div>
    );
};

export default EmailActivitiesTab;
