import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { selectionFormMethods, ICareWorkOrder } from "../../../../interfaces";
import { erpService } from "../../../../api";
import { useLoad, useToast } from "../../../../contexts";
import { List, TextBox } from "devextreme-react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useJhaFormContext } from "../../context/jha-form-context";

const ICareSelectionForm = forwardRef<selectionFormMethods>((props, ref) => {
  const { formDetails, handleFormChange } = useJhaFormContext();
  const { showLoader } = useLoad();
  const { showErrorToast } = useToast();
  const [iCareWo, setICareWo] = useState<ICareWorkOrder[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [noDataText, setNoDataText] = useState("Please search with WO Id");
  useImperativeHandle(ref, () => ({
    validateForm: () => {
      return !!formDetails.jobDetails.workOrderNumber;
    },
  }));

    const fetchIcareWo = useCallback(async (search: string) => {
        if (search?.length < 3) return;
        try {
            setNoDataText('Searching...')
            showLoader(true);
            const response = await erpService.getICareWorkOrders(search);
            if (response.success) {
                if(!!response.data){
                    setICareWo([response.data]);
                } 
            }

        } catch (e) {
            console.log(e);
            showErrorToast("An error occurred while fetching D365 Work Orders");
        }
        finally {
            showLoader(false);
            setNoDataText('No Data Found!')
        }

    }, []);

    useEffect(() => {
        if(!!formDetails.jobDetails?.workOrderNumber)
            fetchIcareWo(formDetails.jobDetails?.workOrderNumber)
    }, []);
    const renderListItem = useCallback((job: ICareWorkOrder) => {
        return (
            <div className='card-container'>
                <Toolbar className='first-toolbar'>
                    <Item
                        location='before'
                        text={`WO #: ${job?.woId}`}
                    />
                    <Item
                        location='after'
                        text={`CTC: ${job?.locationDetails.siteDetails.ctcId + '-' + job?.locationDetails.siteDetails.ctcName}`}
                    />
                </Toolbar>
                <Toolbar>
                    <Item
                        location='before'
                        render={() => <i className="dx-icon-globe"></i>}
                    />
                    <Item
                        location='before'
                        text={job?.locationDetails.siteDetails?.siteName ?? ''}
                    />
                </Toolbar>
                <Toolbar className='last-toolbar'>
                    <Item
                        location='center'
                        widget='dxButton'
                        options={{
                            icon: formDetails.jobDetails?.workOrderNumber === job.woId ? 'check' : 'plus',
                            text: formDetails.jobDetails?.workOrderNumber === job.woId ? 'Selected' : 'Select',
                            type: 'default',
                            disabled: formDetails.jobDetails?.workOrderNumber === job.woId,
                            onClick: () => {
                                handleFormChange('workOrderNumber', job?.woId ?? '', 'jobDetails');
                                handleFormChange('coveringCTC', job?.locationDetails?.siteDetails.ctcId ?? '', 'jobDetails');
                                handleFormChange('siteName', job?.locationDetails.siteDetails.siteName ?? '', 'jobDetails');
                            },
                        }}
                    />
                </Toolbar>
            </div>
        );
    }, [formDetails]);
  return (
    <div className="spectrum-selection-form">
      <TextBox
        placeholder="Enter WO Id..."
        showClearButton={true}
        value={searchTerm}
        onValueChanged={(e) => setSearchTerm(e.value)}
        onKeyDown={(e: any) => {
          if (e.event.key === "Enter") {
            fetchIcareWo(e.event.target.value);
          }
        }}
        buttons={[
          {
            name: "search",
            location: "before",

            options: {
              icon: "search",
              type: "default",
            },
          },
        ]}
      ></TextBox>
      <div className="spectrum-joblist-container">
        <List
          className="spectrum-job-list"
          dataSource={iCareWo}
          height="auto"
          itemRender={renderListItem}
          noDataText={noDataText}
        />
      </div>
    </div>
  );
});
export default ICareSelectionForm;