// JhaAttachmentsComponent.tsx
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, List, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { IJobCreationForm, IPostWorkReview } from '../../../../interfaces';
import { CustomRule, Label, RequiredRule, SimpleItem } from 'devextreme-react/cjs/form';
import './edit-job.scss';
import ReadOnlyFieldWithArrow from '../../../../components/Customizatons/CustomFIelds/readonly-field-with-arrow';
import { InspectionForm } from '../../../inspections/forms/inspection-form';

export interface JhaEditProps {
    onCancel: () => void;
    onSave: (formDetails: IJobCreationForm) => void;
    initialForm: IJobCreationForm;
}

const JhaEdit: React.FC<JhaEditProps> = ({ onCancel, onSave, initialForm }) => {
    const formRef = useRef<Form>(null);
    const [jobPayload, setJobPayload] = useState<IJobCreationForm>(initialForm);
    const [personsTrainedInFirstAid, setPersonsTrainedInFirstAid] = useState<string[]>(jobPayload.colleagues.filter(c => c.isTrainedInFirstAid)?.map(colleague => colleague.email));
    const [showInspectionCreationView, setShowInspectionCreationView] = useState(false);

    const colleagues = [{
        key: "Person Trained in first Aid",
        items: jobPayload.colleagues
    }]
    const GroupTemplate = useCallback((item: any) => {
        return "Person(s) Trained in first Aid";
    }, [personsTrainedInFirstAid]);
    const onSelectedItemKeysChange = useCallback((e: any) => {
        if (e.name === 'selectedItemKeys') {
            const newSelectedIds = e.value;
            if (newSelectedIds.sort().toString() !== personsTrainedInFirstAid.sort().toString()) {
                setPersonsTrainedInFirstAid(newSelectedIds);
            }
        }
    }, [personsTrainedInFirstAid, setPersonsTrainedInFirstAid]);

    useEffect(() => {
        const updatedColleagues = jobPayload.colleagues.map(colleague => ({
            ...colleague,
            isTrainedInFirstAid: personsTrainedInFirstAid.includes(colleague.email)
        }));
        console.log(updatedColleagues)
    }, [personsTrainedInFirstAid]);
    const handleSave = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            onSave(jobPayload);
        }
    };
    const completeButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleSave,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onCancel,
    };
    const backButtonOptions = {
        icon: 'back',
        onClick: onCancel
    };
    const closeInspectionCreationForm = () => {
        setShowInspectionCreationView(false)
    }
    const onInspectionAdded = (id: number) => {
        setJobPayload((payload) => {
            return {
                ...payload,
                preworkInspectionDetails:[...payload.preworkInspectionDetails,id]
            }
        })
        setShowInspectionCreationView(false)
    }
    return (
        <>
            {!showInspectionCreationView &&
                <div className='step-form job-edit-form'>
                    <Toolbar className='form-header'>
                        <Item location="before"
                            widget="dxButton"
                            options={backButtonOptions} />
                        <Item location="center"
                            locateInMenu="never"
                            text={'Edit JHA'} />
                    </Toolbar>
                    <div className='form-fields' style={{ maxHeight: '80vh', overflowY: 'auto' }}>


                        <Toolbar >
                            <Item
                                location='before'
                                text={'JHA ID'}
                            />
                            <Item
                                cssClass='item-value'
                                location='after'
                                text={initialForm.id.toString()}
                            />
                        </Toolbar>
                        <Toolbar >
                            <Item
                                location='before'
                                text={'CTC'}
                            />
                            <Item
                                cssClass='item-value'
                                location='after'
                                text={initialForm?.jobDetails.coveringCTC?.toString()}
                            />
                        </Toolbar>
                        <Toolbar >
                            <Item
                                location='before'
                                text={'Work Order ID'}
                            />
                            <Item
                                cssClass='item-value'
                                location='after'
                                text={initialForm?.jobDetails.jobNumber || initialForm?.jobDetails.workOrderNumber}
                            />
                        </Toolbar>

                        <Toolbar >
                            <Item
                                location='before'
                                text={'Site Name'}
                            />
                            <Item
                                cssClass='item-value'
                                location='after'
                                text={initialForm?.jobDetails.siteName}
                            />
                        </Toolbar>
                        <Form
                            formData={jobPayload}
                            ref={formRef}
                            style={{ marginTop: '10px' }}
                        >
                            <SimpleItem dataField="externalColleagues"
                                editorType="dxTextBox"
                                editorOptions={{
                                    placeholder: "Type here...",
                                    showClearButton: true
                                }}>
                                <Label text="External Colleagues" />

                            </SimpleItem>
                            <SimpleItem dataField="permitNumber"
                                editorType="dxTextBox"
                                editorOptions={{
                                    placeholder: "Type here...",
                                    showClearButton: true
                                }}>
                                <Label text="Permit Number" />

                            </SimpleItem>
                            <SimpleItem dataField="safetyPlan"
                                editorType="dxTextBox"
                                editorOptions={{
                                    placeholder: "Type here...",
                                    showClearButton: true
                                }}>
                                <Label text="Safety Plan" />

                            </SimpleItem>

                        </Form>
                        <List
                        className='persons-trained'
                            dataSource={colleagues}
                            grouped={true}
                            keyExpr={'email'}
                            displayExpr={'name'}
                            showSelectionControls
                            height={'fit-content'}
                            selectionMode='multiple'
                            collapsibleGroups={true}
                            groupTemplate={GroupTemplate}
                            onOptionChanged={onSelectedItemKeysChange}
                            selectedItemKeys={personsTrainedInFirstAid}
                        />
                        <ReadOnlyFieldWithArrow value={'Prework Inspection'} onClick={() => setShowInspectionCreationView(true)} />
                    </div>
                    <Toolbar className='form-buttons'>
                        <Item
                            widget='dxButton'
                            location='before'
                            options={cancelButtonOptions}

                        />
                        <Item
                            widget='dxButton'
                            location='after'
                            options={completeButtonOptions} />
                    </Toolbar>

                </div>}
            {showInspectionCreationView &&
                <InspectionForm completedInspections={jobPayload.preworkInspectionDetails} onCancelInspection={closeInspectionCreationForm} onInspectionAdded={onInspectionAdded}></InspectionForm>
            }
        </>

    );
};

export default JhaEdit;
