import React, { useCallback, useEffect, useMemo, useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import "./incidents.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoad, useNavigation } from "../../contexts";
import { ButtonTypes } from "devextreme-react/cjs/button";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import Popover from "devextreme-react/popover";
import List from "devextreme-react/list";
import IncidentReport from "./components/incident-report/incident-report";
import { IIncidentCreationForm } from "../../interfaces";
import VehicleAccidentReport from "./components/vehicle-accident-report/vehicle-accident-report";
import { incidentService } from "../../api/incidents";

enum IncidentViews {
  Home,
  GeneralIncident,
  VehicleAccident,
}

export default function Incidents() {
  const [currentView, setCurrentView] = useState(IncidentViews.Home);
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddMenuVisible, setAddMenuVisible] = useState(false);
  const { user } = useAuth0();
  const { showLoader } = useLoad();
  const { logout } = useAuth0();
  const { setShowHeader } = useNavigation();
  const [incidents, setIncidents] = useState<IIncidentCreationForm[]>([]);

  const fetchIncidents = useCallback(async () => {
    showLoader(true);
    try {
      const response = await incidentService.getAllByRegion();
      if (response.success) {
        const sortedIncidents = response.data.sort(
          (a, b) =>
            (b.createdDate
              ? new Date(b.createdDate).getTime()
              : new Date().getTime()) -
            (a.createdDate
              ? new Date(a.createdDate).getTime()
              : new Date().getTime())
        );
        setIncidents(sortedIncidents);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, [showLoader]);

  useEffect(() => {
    fetchIncidents();
  }, [fetchIncidents]);

  useEffect(() => {
    setShowHeader(currentView === IncidentViews.Home);
  }, [currentView, setShowHeader]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }, [logout]);

  const backButtonOptions = {
    icon: "login",
    onClick: handleLogout,
  };

  const addButtonOptions = {
    icon: "plus",
    onClick: () => {
      setAddMenuVisible(!isAddMenuVisible); // Toggle visibility of the add menu
    },
  };

  const searchButtonOptions = {
    icon: "search",
    onClick: () => {
      setSearchBarVisible(!isSearchBarVisible); // Toggle visibility
    },
  };

  const closeSearchButtonOptions = useMemo<ButtonTypes.Properties>(
    () => ({
      icon: "back",
      stylingMode: "text",
      onClick: () => {
        setSearchBarVisible(false);
        setSearchTerm("");
      },
    }),
    []
  );

  const closeIncidentCreationForm = () => {
    setCurrentView(IncidentViews.Home);
  };

  const onIncidentAdded = async (payload: IIncidentCreationForm) => {
    console.log("Incident Created:", payload);
    const submittedIncident = await incidentService.create(payload);
    if (submittedIncident.success) {
      fetchIncidents();
      setCurrentView(IncidentViews.Home);
    }
  };

  const handleMenuItemClick = (e: any) => {
    const itemData = e.itemData;
    if (itemData.id === "incidentReport") {
      setCurrentView(IncidentViews.GeneralIncident);
    } else if (itemData.id === "vehicleAccidentReport") {
      setCurrentView(IncidentViews.VehicleAccident);
    }
    setAddMenuVisible(false); // Close the add menu
  };

  const addMenuItems = [
    { id: "incidentReport", text: "Incident Report", icon: "user" },
    {
      id: "vehicleAccidentReport",
      text: "Vehicle Accident Report",
      icon: "car",
    },
  ];

  const renderListItem = (incident: IIncidentCreationForm) => {
    return (
      <div className="card-container">
        <Toolbar className="first-toolbar">
          <Item location="before" text={"Reported by:"} />
          <Item
            location="after"
            text={
              incident.generalIncident?.affectedCollegue?.[0]?.name ||
              incident.vehicleIncident?.associatedConvergentColleague?.[0]
                ?.name ||
              "N/A"
            }
          />
        </Toolbar>
        <Toolbar>
          <Item
            location="before"
            render={() => <i className="dx-icon-event"></i>}
          />
          <Item location="before" text={"Reported Date"} />
          <Item
            location="after"
            text={
              incident?.incidentDate
                ? new Date(incident?.incidentDate + "Z")?.toLocaleString()
                : new Date()?.toLocaleDateString()
            }
            cssClass="item-value"
          />
        </Toolbar>
        <Toolbar>
          <Item
            location="before"
            render={() => <i className="dx-icon-event"></i>}
          />
          <Item location="before" text={"Created Date"} />
          <Item
            location="after"
            text={
              incident?.createdDate
                ? new Date(incident?.createdDate + "Z")?.toLocaleString()
                : new Date()?.toLocaleDateString()
            }
            cssClass="item-value"
          />
        </Toolbar>
        <Toolbar className="last-toolbar">
          <Item location="before" text="Status:" />
          <Item
            location="after"
            cssClass={`incident-status-${incident.incidentStatus}`}
            text={incident.incidentStatus}
          />
        </Toolbar>
      </div>
    );
  };

  const filteredIncidents = useMemo(() => {
    return incidents.filter((incident) => {
      const generalIncidentColleague =
        incident.generalIncident?.affectedCollegue?.[0]?.name || "";
      const vehicleIncidentColleague =
        incident.vehicleIncident?.associatedConvergentColleague?.[0]?.name ||
        "";
      const status = incident.incidentStatus || "";

      const searchTermLower = searchTerm.toLowerCase();

      return (
        generalIncidentColleague.toLowerCase().includes(searchTermLower) ||
        vehicleIncidentColleague.toLowerCase().includes(searchTermLower) ||
        status.toLowerCase().includes(searchTermLower)
      );
    });
  }, [incidents, searchTerm]);

  return (
    <div className="incidents-home">
      {currentView === IncidentViews.Home && !isSearchBarVisible && (
        <Toolbar id="incidents-toolbar">
          <Item
            location="before"
            widget="dxButton"
            options={backButtonOptions}
          />
          <Item
            location="center"
            locateInMenu="never"
            text={"Incident Reports"}
          />
          <Item
            location="after"
            locateInMenu="never"
            widget="dxButton"
            options={searchButtonOptions}
          />
          <Item
            location="after"
            locateInMenu="never"
            widget="dxButton"
            options={addButtonOptions}
          />
        </Toolbar>
      )}
      {currentView === IncidentViews.Home && isSearchBarVisible && (
        <TextBox
          placeholder="Search Incidents"
          showClearButton={true}
          value={searchTerm}
          onValueChanged={(e) => setSearchTerm(e.value)}
        >
          <TextBoxButton
            name="closeSearch"
            location="before"
            options={closeSearchButtonOptions}
          />
        </TextBox>
      )}
      {currentView === IncidentViews.Home && (
        <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <List
            className="incidents-list"
            dataSource={filteredIncidents}
            height="auto"
            itemRender={renderListItem}
          />
        </div>
      )}
      {currentView === IncidentViews.GeneralIncident && (
        <IncidentReport
          onCancel={closeIncidentCreationForm}
          onSubmit={onIncidentAdded}
        />
      )}
      {currentView === IncidentViews.VehicleAccident && (
        <VehicleAccidentReport
          onCancel={closeIncidentCreationForm}
          onSubmit={onIncidentAdded}
        />
      )}
      <Popover
        target="#incidents-toolbar"
        visible={isAddMenuVisible}
        onHiding={() => setAddMenuVisible(false)}
        showTitle={false}
        width={300}
        hideOnOutsideClick={true}
        position={{
          my: "right top",
          at: "right bottom",
          of: "#incidents-toolbar",
          offset: "0 0", // Adjust offset as needed
        }}
      >
        <List
          dataSource={addMenuItems}
          displayExpr="text"
          onItemClick={handleMenuItemClick}
        />
      </Popover>
    </div>
  );
}
