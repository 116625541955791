import { ISiteCondition } from '../../interfaces'
import { ApiService } from '../ApiService';



class SiteConditionService extends ApiService<ISiteCondition> {
  constructor() {
    super('SiteCondition');
  }

  // Additional methods specific to the User model can be added here
}

export const siteConditionService = new SiteConditionService();