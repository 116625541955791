// JhaAttachmentsComponent.tsx
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { IIncidentCreationForm, IJobCreationForm, IPostWorkReview } from '../../../../interfaces';
import { ButtonItem, ButtonOptions, CustomRule, IButtonItemProps, Label, RequiredRule, SimpleItem } from 'devextreme-react/cjs/form';
import './post-work-review.scss';
import IncidentReport from '../../../incidents/components/incident-report/incident-report';
import { incidentService } from '../../../../api/incidents';

export interface JhaPostWorkReviewProps {
    onCancelJhaCompletion: () => void;
    onCompleteJha: (formDetails: IPostWorkReview) => void;
    initialForm?: IJobCreationForm;
}
enum PostWorkReviewViews {
    Home,
    IncidentReport
}

const JhaPostWorkReview: React.FC<JhaPostWorkReviewProps> = ({ onCancelJhaCompletion, onCompleteJha, initialForm }) => {
    const formRef = useRef<Form>(null);
    const [currentView, setCurrentView] = useState(PostWorkReviewViews.Home);
    const [completionPayload, setCompletionPayload] = useState<IPostWorkReview>({
        jobId: initialForm?.id || 0,
        incidentId: 0,
        isHazardsLeft: false,
        isInjuriesOccured: false,
        istoolsAndMaterialsStored: false,
        postWorkHazardDetails: '',
        postWorkInjuryDetails: ''
    });
    const handleComplettion = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            onCompleteJha(completionPayload);
        }
    };
    const completeButtonOptions = {
        text: 'Complete',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleComplettion,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onCancelJhaCompletion,
    };
    const closeIncidentCreationForm = () => {
        setCurrentView(PostWorkReviewViews.Home);
    };

    const onIncidentAdded = useCallback( async (payload: IIncidentCreationForm) => {
        const submmitedIncident = await incidentService.create(payload);
        if (submmitedIncident.success) {
            setCompletionPayload({...completionPayload, incidentId : submmitedIncident.data.id})
            setCurrentView(PostWorkReviewViews.Home);
        }

    },[completionPayload]);
    return (
        <>
        {currentView === PostWorkReviewViews.Home && <div className='step-form post-work-review'>
            <Toolbar className='form-header'>
                <Item location="center"
                    locateInMenu="never"
                    text={'Post-Work Review'} />
            </Toolbar>
            <div className='form-fields' style={{ maxHeight: '80vh', overflowY: 'auto' }}>


                <Toolbar >
                    <Item
                        location='before'
                        text={'Job/WO Number'}
                    />
                    <Item
                        cssClass='item-value'
                        location='after'
                        text={initialForm?.jobDetails.jobNumber || initialForm?.jobDetails.workOrderNumber}
                    />
                </Toolbar>
                <Toolbar >
                    <Item
                        location='before'
                        text={'Site Name'}
                    />
                    <Item
                        cssClass='item-value'
                        location='after'
                        text={initialForm?.jobDetails.siteName}
                    />
                </Toolbar>
                <Form
                    formData={completionPayload}
                    ref={formRef}
                    style={{ marginTop: '10px' }}
                >
                    <SimpleItem
                        editorType="dxRadioGroup"
                        dataField="isInjuriesOccured"
                        editorOptions={{
                            items: [
                                { value: 1, text: 'Yes' },
                                { value: 0, text: 'No' }
                            ], valueChangeEvent: 'change', layout: 'horizontal', valueExpr: "value", displayExpr: "text"
                        }}>
                        <Label text="Did any injuries or incidents occur during your planned work?" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="postWorkInjuryDetails"
                        editorType="dxTextArea"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Post Work Injury Details" />
                        {/* <CustomRule
                            message="Post Work Injury Details are mandatory"
                            validationCallback={e => {
                                if (!!completionPayload.isInjuriesOccured) {
                                    return !!e.value;
                                }
                                return true;
                            }}
                        /> */}
                    </SimpleItem>
                    <ButtonItem 
                    horizontalAlignment='center'>
                        <ButtonOptions 
                         text={!!completionPayload.incidentId ?'Incident Report Submitted': 'Create Incident Report'}
                         type= "default"
                         icon= {!!completionPayload.incidentId ?'check':'add'}
                         stylingMode= "outlined"
                         disabled={!!completionPayload.incidentId}
                         onClick= {() => setCurrentView(PostWorkReviewViews.IncidentReport)}
                        >

                        </ButtonOptions>

                    </ButtonItem>
                    <SimpleItem
                        editorType="dxRadioGroup"
                        dataField="isHazardsLeft"
                        editorOptions={{
                            items: [
                                { value: 1, text: 'Yes' },
                                { value: 0, text: 'No' }
                            ], valueChangeEvent: 'change', layout: 'horizontal', valueExpr: "value", displayExpr: "text"
                        }}>
                        <Label text="Have any hazards been left in the work area?" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="postWorkHazardDetails"
                        editorType="dxTextArea"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Post Work Hazard Details" />
                        {/* <CustomRule
                            message="Post Work Hazard Details are mandatory"
                            validationCallback={e => {
                                if (!!completionPayload.isHazardsLeft) {
                                    return !!e.value;
                                }
                                return true;
                            }}
                        /> */}
                    </SimpleItem>
                    <SimpleItem
                        editorType="dxRadioGroup"
                        dataField="istoolsAndMaterialsStored"
                        editorOptions={{
                            items: [
                                { value: 1, text: 'Yes' },
                                { value: 0, text: 'No' }
                            ], valueChangeEvent: 'change', layout: 'horizontal', valueExpr: "value", displayExpr: "text"
                        }}>
                        <Label text="Have all tools, materials and waste been stored or removed?" />
                        <RequiredRule />
                    </SimpleItem>
                    
                </Form>
            </div>
            <Toolbar className='form-buttons'>
                <Item
                    widget='dxButton'
                    location='before'
                    options={cancelButtonOptions}

                />
                <Item
                    widget='dxButton'
                    location='after'
                    options={completeButtonOptions} />
            </Toolbar>
        </div>}
        {
                currentView == PostWorkReviewViews.IncidentReport &&
                <IncidentReport postWorkReview={initialForm} onCancel={closeIncidentCreationForm} onSubmit={onIncidentAdded}></IncidentReport>
            }
        </>
    );
};

export default JhaPostWorkReview;
