import { List } from "devextreme-react";
import { useState } from "react";
import { IEmailTabPanelProps, IJhaColleague } from "../../../interfaces";
import { ISafeReadOnlyFieldWithTick } from "../../Customizatons";



const EmailColleaguesTab: React.FC<IEmailTabPanelProps> = ({ initialForm }) => {
    const [selectedUsers, setSelectedUsers] = useState<IJhaColleague[]>(initialForm.colleagues);

    return <div className="email-colleagues-tab">
        {selectedUsers.length > 0 && (
        <List
          dataSource={selectedUsers}
          className='selected-users'
          displayExpr={'email'}
          itemRender={(colleague: IJhaColleague) => (
            <ISafeReadOnlyFieldWithTick
              value={colleague.name}
              description={colleague.email}
              hideTick
            />
          )}
        />
      )}
    </div>
}
export default EmailColleaguesTab;