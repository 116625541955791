import { useCallback, useEffect, useState } from "react";
import { Button, List, LoadIndicator, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/cjs/toolbar";
import { useJhaFormContext } from "../../context/jha-form-context";
import { SafetySubFormProps } from "../safety-form";
import { siteConditionService } from "../../../../api";
import { ISiteCondition } from "../../../../interfaces";
import ReadOnlyFieldWithTick from "../../../Customizatons/CustomFIelds/readonly-field-with-tick";
import siteSpecificHazardsImage from "./images/step3.png";
import '../../jha-creation-multiview.scss';

const SiteSpecificHazards: React.FC<SafetySubFormProps> = ({ onNextButtonClick, onPreviousButtonClick }) => {
  const { formDetails, handleFormChange } = useJhaFormContext();
  const [siteConditions, setSiteConditions] = useState<ISiteCondition[]>([]);
  const [selectedSiteConditions, setSelectedSiteConditions] = useState<number[]>(formDetails.siteSpecificHazards || []);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSiteConditions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await siteConditionService.getAllByRegion();
      if (response?.success) {
        let activeItems = response?.data || [];
        const noneItem = {
          id: -1,
          name: "None",
          isActive: true,
          createdDate: new Date(),
          regionId: -1,
          jobHazards: [],
          preworkInspections: [],
        };
        activeItems?.sort((a, b) => a.name.localeCompare(b.name));
        activeItems = [noneItem, ...activeItems];
        setSiteConditions(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSiteConditions();
  }, [fetchSiteConditions]);

  useEffect(() => {
    let conditions = selectedSiteConditions.filter((id) => id !== -1);
    handleFormChange("isSiteHazardsSelected", conditions.length > 0);
    handleFormChange("siteSpecificHazards", conditions);
  }, [selectedSiteConditions, handleFormChange]);

  const renderItem = useCallback((data: ISiteCondition) => {
      const isSelected = selectedSiteConditions.includes(data.id);
      return (
        <div className={`list-item${isSelected ? " selected" : ""}`}>
          <ReadOnlyFieldWithTick
            value={data.name}
            hideTick={!isSelected}
            onClick={() => console.log("Site Condition selected")}
          />
        </div>
      );
    },
    [selectedSiteConditions]
  );

  const onItemClick = useCallback((e: any) => {
    const selectedSiteConditionId = e.itemData.id;
    setSelectedSiteConditions((prevSelected) =>
      prevSelected.includes(selectedSiteConditionId)
        ? prevSelected.filter((id) => id !== selectedSiteConditionId)
        : [...prevSelected, selectedSiteConditionId]
    );
  }, []);

  return (
    <>
      <Toolbar className="form-header">
        <Item location="before">
          <Button
            icon="back"
            stylingMode="text"
            onClick={() => {
              onPreviousButtonClick();
            }}
          />
        </Item>
        <Item location="center" text="Safety" />
      </Toolbar>
      <div className="site-conditions-list">
        <img
          src={siteSpecificHazardsImage}
          alt="Site Specific Hazards"
          className="sites-hazard-step-img"
        />
        {isLoading ? (
          <div className="site-loading-indicator">
            <LoadIndicator />
          </div>
        ) : (
          <List
            dataSource={siteConditions}
            displayExpr="name"
            keyExpr="id"
            itemRender={renderItem}
            selectionMode="multiple"
            selectByClick={true}
            searchExpr="name"
            onItemClick={onItemClick}
            selectedItemKeys={selectedSiteConditions}
          />
        )}
      </div>
      <Toolbar className="form-buttons">
        <Item location="before">
          <Button
            text="Previous"
            stylingMode="outlined"
            onClick={() => {
              onPreviousButtonClick();
            }}
          />
        </Item>
        <Item location="after">
          <Button
            text="Next"
            type="default"
            stylingMode="contained"
            onClick={() => {
              onNextButtonClick();
            }}
            disabled={selectedSiteConditions.length <= 0}
          />
        </Item>
      </Toolbar>
    </>
  );
};
export default SiteSpecificHazards;