import React, { useCallback, useMemo, useState } from 'react';
import Popup, { IPopupOptions, ToolbarItem } from 'devextreme-react/popup';
import { List } from 'devextreme-react';
import { SelectionChangedEvent } from 'devextreme/ui/list';
interface ListPickerOption {
    id: number;
    name:string
}
interface ListPickerProps {
    visible: boolean;
    title: string;
    onCancel: () => void;
    onSelected: (id: number) => void;
    height:number;
    options:ListPickerOption[];
    defaultValue?:number;
    disableCancel?:boolean;
}
const animationConfig: IPopupOptions['animation'] = {
    show: {
        type: 'slide',
        from: {
          position:{
            my:'center',
            at:'center',
            of: window
          }
        },
        to: {
            position:{
                my:'top',
                at:'bottom',
                of: window
              }
        },
      }
  };

  
const ListPickerDialog: React.FC<ListPickerProps> = ({
    visible,
    title,
    onCancel,
    onSelected,
    height,options,defaultValue,disableCancel
}) => {
    const[selectedItem,setSelectedItem] = useState<number | undefined>(defaultValue);
    const handleConfirm = useCallback(() => {
        if (selectedItem !== undefined) {
          onSelected(selectedItem);
        }
      }, [selectedItem, onSelected]); 
    const onSelectedItemKeysChange = useCallback((e:SelectionChangedEvent) => {
        setSelectedItem(e.addedItems[0]?.id)
      }, [setSelectedItem]);
    const cancelButtonOptions = useMemo(() => ({
        text: 'Cancel',
        type: 'default',
        stylingMode: 'text',
        onClick: onCancel,
        disabled: (selectedItem==undefined || selectedItem==null || disableCancel)
    }), [onCancel, selectedItem,disableCancel]);

    const confirmButtonOptions = useMemo(() => ({
        text: 'Confirm',
        type: 'default',
        stylingMode: 'text',
        onClick: handleConfirm,
        disabled: (selectedItem==undefined || selectedItem==null)
    }), [handleConfirm, selectedItem]);
    const titleButtonOptions = useMemo(() => ({
        text: title,
        type: 'default',
        stylingMode: 'text',
      }), [title]);
    
    return (

        
        <Popup
            visible={visible}
            onHiding={onCancel}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showTitle={true}
            position={'bottom'}
            width={390}
            height={height ? height:238}
            animation={{
                show: {
                    type: 'slide',
                    from: {
                        position: {
                            my: 'top',
                            at: 'bottom',
                            of: window
                        }
                    },
                    to: {
                        position: {
                            my: 'bottom',
                            at: 'bottom',
                            of: window
                        }
                    }
                }
            }}
        >
            <List 
            className='list-with-center-allignment'
            dataSource={options}
            selectionMode={'single'}
            selectByClick={true}
            displayExpr="name"
            onSelectionChanged={onSelectedItemKeysChange}
            selectedItems={options.filter(i => i.id==selectedItem)}>

            </List>
            <ToolbarItem
                cssClass='list-picker-button'
                options={cancelButtonOptions}
                widget="dxButton"
                location="before"
                toolbar="top"
            />
            <ToolbarItem
                cssClass='list-picker-title'
                options={titleButtonOptions}
                widget="dxButton"
                location="center"
                toolbar="top"
            />
            <ToolbarItem
                cssClass='list-picker-button'
                options={confirmButtonOptions}
                widget="dxButton"
                location="after"
                toolbar="top"
            />
        </Popup>
    );
};

export default ListPickerDialog;

