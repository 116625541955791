

export enum FormType {
    CHA = "CHA",
    JHA = "JHA"
  }
  export enum JobStatus {
    COMPLETE = "Completed",
    INCOMPLETE = "InComplete"
  }
export interface IJobCreationForm {
    [key: string]: any | { [key: string]: any };
    id: number;
    createdDate:Date;
    regionId: number;
    permitNumber:string;
    musterPoint:string;
    personsTrainedInFirstAid:string[];//List of email Ids
    jobDetails:IJobDetails;
    colleagues:IJhaColleague[];
    activities:number[]; //List of the activity Ids
    preworkInspectionDetails:number[];
    personalProtectiveEquipment:number[];//list of protective equipment selected
    siteSpecificHazards:number[] //list of site specific hazard Ids
    jobHazardsAndControls:IJobHazardsAndControls[];
    hseAcknowledgements:number[];//List of HSE acknowledgement
    chaHazardAnalysisDetails:IChaHazardAnalysisDetails[];
    chaAcknowledgementId:number;
    externalColleagues?:string; //stores the comma seperated values of the external colleagues
    formType: FormType,
    attachments:string[],
    isInspectionsSelected :boolean,
    isPPEsSelected:boolean,
    isSiteHazardsSelected:boolean,
    jobStatus?:string,
    safetyPlan?: string
}

export interface IJobDetails {
    jobNumber?:string;
    siteName?:string;
    coveringCTC?:string;
    type:JobType;
    workOrderNumber?:string;
}
export enum JobType {
    'Job#',
    WorkOrder
}
export interface IJhaColleague {
    email:string;
    name:string;
    isTrainedInFirstAid:boolean;
    isDefault?:boolean;
}

export interface IPreworkInspectionDetails {
    inspectionId:number;
    isOtherInpection:boolean;
    otherInspectionName:string;
    isVisualInspection:boolean;
    isCertifiedInspection:boolean;
}
export interface IJobHazardsAndControls{
    hazardId:number;
    totalControlMeasures:number[];
    selectedControlMeasures:number[];
    additionalControlMeasures:string;
    isAdditionalHazard:boolean;
}
export interface IChaHazardAnalysisDetails {
    chaAnalysisId:number;
    selectionPoints:number[];
}