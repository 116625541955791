import React, { useCallback, useRef, useState } from 'react';
import { Form, Toolbar, SelectBox } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import { IIncidentCreationForm, IJhaColleague, IJobCreationForm, IPostWorkReview, IUser, IncidentStatus, IncidentType } from '../../../../interfaces';
import { Label, RequiredRule, SimpleItem } from 'devextreme-react/form';
import './incident-report.scss';
import ISafeFileUploader from '../../../../components/Customizatons/FileUploadComponent/FileUploadComponent';
import { erpService } from '../../../../api';
import { debounce } from 'lodash';
import { useLoad } from '../../../../contexts';

export interface IncidentReportProps {
    postWorkReview?: IJobCreationForm
    onCancel: () => void;
    onSubmit: (formDetails: IIncidentCreationForm) => void;
}

const IncidentReport: React.FC<IncidentReportProps> = ({ onCancel, onSubmit, postWorkReview }) => {
    const formRef = useRef<Form>(null);
    const { showLoader } = useLoad();
    const [completionPayload, setCompletionPayload] = useState<IIncidentCreationForm>({
        id: 0,
        createdDate: new Date(),
        description: '',
        images: [],
        incidentDate: new Date(),
        incidentType: IncidentType.GENERAL,
        isJhaCompleted: false,
        jobId: postWorkReview?.id || 0,
        jobNumber: postWorkReview?.jobDetails?.jobNumber ?postWorkReview?.jobDetails?.jobNumber : postWorkReview?.jobDetails?.workOrderNumber || '',
        ctc:postWorkReview?.jobDetails?.coveringCTC || '',
        regionId: Number(localStorage.getItem('region')) || 0,
        incidentStatus: IncidentStatus.REPORTED,
        generalIncident: {
            affectedCollegue: [],
            jobTitle: '',
            mechanismOfInjury: '',
            siteLocation: postWorkReview?.jobDetails?.siteName || '',
            typeOfInjury: ''
        }
    });

    const [colleagues, setColleagues] = useState<IUser[]>([]);
    const debounceFetchUsers = useCallback(
        debounce((term) => handleSearch(term), 1000),
        []
    );
    const handleSearch = useCallback(async (e: any) => {
        try {
            const value = e.component.option('text');
            if (value.length >= 3) {
                showLoader(true)
                const response = await erpService.getAllUsers(value); //modify thsi based on the users API
                setColleagues(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }

    }, []);

    const handleSelectionChange = useCallback((e: any) => {
        const selectedColleague = e.selectedItem as IUser;
        console.log(e)
        if (!!completionPayload.generalIncident)
            setCompletionPayload({
                ...completionPayload,
                generalIncident: {
                    ...completionPayload.generalIncident,
                    affectedCollegue: selectedColleague ? [{
                        name:selectedColleague.firstName +' '+selectedColleague.lastName,
                        email:selectedColleague.emailId,
                        isTrainedInFirstAid: false
                    }] : []
                }
            });
    }, [completionPayload]);

    const handleComplettion = useCallback(() => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var formattedPayload = {
                ...completionPayload,
                isJhaCompleted: !!completionPayload.isJhaCompleted
            }
            onSubmit(formattedPayload);
        }
    }, [completionPayload, onSubmit]);

    const completeButtonOptions = {
        text: 'Submit',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleComplettion,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onCancel,
    };

    const backButtonOptions = {
        icon: 'back',
        onClick: onCancel
    };

    const uploadedFileIds = useCallback((ids: string[]) => {
        setCompletionPayload({
            ...completionPayload,
            images: [...ids]
        });
    }, [completionPayload]);

    return (
        <div className='step-form incident-report'>
            <Toolbar className='form-header'>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    locateInMenu="never"
                    text={'Create Incident Reports'} />
            </Toolbar>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <Form
                    formData={completionPayload}
                    ref={formRef}
                    style={{ marginTop: '10px' }}
                >
                    <SimpleItem dataField="jobNumber"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Job/Work Order Number" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem>
                        <Label text="Affected Colleague" />
                        <SelectBox
                            dataSource={colleagues}
                            searchEnabled={true}
                            valueExpr="emailId"
                            displayExpr={(item) => item ? `${item.firstName} ${item.lastName}` : ''}
                            searchTimeout={5000}
                            minSearchLength={3}
                            showClearButton={true}
                            onInput={debounceFetchUsers}
                            onSelectionChanged={handleSelectionChange}
                            placeholder="Search for a colleague..."
                        />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="incidentDate"
                        editorType="dxDateBox">
                        <Label text="Incident Date" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="generalIncident.siteLocation"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Site Location" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="region"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Region" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem
                        editorType="dxRadioGroup"
                        dataField="isJhaCompleted"
                        editorOptions={{
                            items: [
                                { value: 1, text: 'Yes' },
                                { value: 0, text: 'No' }
                            ], valueChangeEvent: 'change', layout: 'horizontal', valueExpr: "value", displayExpr: "text"
                        }}>
                        <Label text="JHA Completed" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="generalIncident.mechanismOfInjury"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Mechanism of Injury" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem 
                     dataField="ctc"
                        editorType="dxTextBox"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="CTC" />
                        <RequiredRule />
                    </SimpleItem>
                    <SimpleItem dataField="description"
                        editorType="dxTextArea"
                        editorOptions={{
                            placeholder: "Type here...",
                            showClearButton: true
                        }}>
                        <Label text="Description" />
                        <RequiredRule />
                    </SimpleItem>
                </Form>
                <ISafeFileUploader initialFileIds={[...completionPayload?.images || []]} uploadedFileIds={uploadedFileIds} />
            </div>
            <Toolbar className='form-buttons'>
                <Item
                    widget='dxButton'
                    location='before'
                    options={cancelButtonOptions}
                />
                <Item
                    widget='dxButton'
                    location='after'
                    options={completeButtonOptions} />
            </Toolbar>
        </div>
    );
};

export default IncidentReport;
