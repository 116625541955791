import { useCallback, useEffect, useMemo, useState } from 'react';
import { useJhaFormContext } from '../../context/jha-form-context';
import preWorkInspectionsImage from './images/step1.png';
import { List, Toolbar } from 'devextreme-react';
import { SafetySubFormProps } from '../safety-form';
import { Item } from 'devextreme-react/cjs/toolbar';
import { ButtonTypes } from 'devextreme-react/cjs/button';
import { inspectionService } from '../../../../api';
import { useLoad } from '../../../../contexts';
import { IInspectionCreationForm, IPreworkInspection, InspectionFormData } from '../../../../interfaces';
import ReadOnlyFieldWithArrow from '../../../Customizatons/CustomFIelds/readonly-field-with-arrow';
import DynamicForm from '../../../inspection-form/inspection-detail-form';
interface InspectionIdPair {
  inspectionId: number;
  previousSubmittedForm:IInspectionCreationForm|null;
  submittedId: number | null; // `null` until the submission is successful
}
const PreWorkInspections: React.FC<SafetySubFormProps> = ({
  onNextButtonClick,
  onPreviousButtonClick
}) => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const { showLoader } = useLoad();
  const [inspections, setInspections] = useState<IPreworkInspection[]>([]);
  const [selectedInspectionIds, setSelectedInspectionIds] = useState<InspectionIdPair[]>([]);
  const [inspectionFormDetails, setInspectionFormDetails] = useState<InspectionFormData | null>(null);
  const [showFormDetails, setShowFormDetails] = useState<boolean>(false);
  const fetchInspections = useCallback(async () => {
    showLoader(true);
    try {
      const response = await inspectionService.getAllByRegion();
      if (response.success) {
        let activeItems = response.data?.filter(i => i.isActive) || [];
        const noneItem = { id: -1, name: 'None', isActive: true, createdDate:new Date(), regionId:-1 };
        activeItems?.sort((a, b) => a.name.localeCompare(b.name));
      // Add the 'None' item to the beginning of the activeItems array
        activeItems = [noneItem, ...activeItems];
        setInspections(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  useEffect(() => {
    // Assuming preworkInspectionDetails are loaded into formDetails
    const { preworkInspectionDetails } = formDetails;

    preworkInspectionDetails.forEach(async (submittedId) => {
        const response = await inspectionService.getInspectionSubmittedForm(submittedId);  // Assuming this API call exists
        if (response.success) {
            const inspectionId = response.data.inspectionId; 
            setSelectedInspectionIds(prev => [
              ...prev.filter(item => item.inspectionId !== inspectionId),
               { inspectionId, submittedId,previousSubmittedForm:response.data }]);
        }
    });
}, [formDetails, setSelectedInspectionIds]);

  const fetchInspectionFormDetails = useCallback(async (inspectionId:number) => {
    showLoader(true);
    try {
      const response = await inspectionService.getFormDetails(inspectionId);
      if (response.success) {
        response.data.id = inspectionId;
        setInspectionFormDetails(response.data);
        setShowFormDetails(true);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  useEffect(() => {
    fetchInspections();
  }, []);

  const renderItem = useCallback((data: IPreworkInspection) => {
    var isSelected = false;
    if(data.id != -1)
      isSelected = selectedInspectionIds?.findIndex(i=> i.inspectionId ==data.id && !!i.submittedId) != -1;
    else
      isSelected = !formDetails.isInspectionsSelected;
    return (
      <div className={`list-item${isSelected ? ' selected' : ''}`}>
        <ReadOnlyFieldWithArrow value={data.name} displayCheck={isSelected} onClick={() => console.log('tst')} />
        
      </div>
    );
  }, [selectedInspectionIds]);
  
  const onSelectedItemKeysChange = useCallback((e: any) => {
    const currentId = e.itemData?.id;
    if (currentId > 0) {
        fetchInspectionFormDetails(currentId);
        // Check if the id is already included
        if (!selectedInspectionIds.some(item => item.inspectionId === currentId)) {
            setSelectedInspectionIds(prev => [...prev, { inspectionId: currentId, submittedId: null,previousSubmittedForm:null }]);
        }
    } else if(e.itemData?.id ==-1){
      handleFormChange('isInspectionsSelected', !formDetails.isInspectionsSelected);
      setSelectedInspectionIds(prev => [...prev, { inspectionId: -1, submittedId: -1,previousSubmittedForm:null }]);
    }
}, [selectedInspectionIds]);

  const previousButtonOptions = useMemo<ButtonTypes.Properties>(
    () => ({
      text: 'Previous',
      type: 'default',
      stylingMode: 'outlined',
      onClick: () => {
        onPreviousButtonClick()
      },
    }),
    [],
  );
  const nextButtonOptions = useMemo<ButtonTypes.Properties>(
    () => ({
      text: 'Next',
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        onNextButtonClick();
      },
    }),
    [],
  );
  const onSubmitInspection = useCallback(async (data: IInspectionCreationForm) => {
    const response = await inspectionService.submitInspectionForm(data);
    if (response.success) {
        const submittedId = response.data.id;  // Assuming the API returns the id as response.data.id
        // Get current inspection IDs without the one that's being updated
        const updatedPreworkInspectionDetails = formDetails.preworkInspectionDetails.filter(
            id => !selectedInspectionIds.some(item => item.submittedId === id && item.inspectionId === data.inspectionId)
        );

        // Add the new submittedId
        submittedId && updatedPreworkInspectionDetails.push(submittedId);

        // Update the form context with the new submitted ID
        handleFormChange('preworkInspectionDetails', updatedPreworkInspectionDetails);
        handleFormChange('isInspectionsSelected', true);
        // Update local state to reflect this change
        setSelectedInspectionIds(prev => [
            ...prev.filter(item => item.inspectionId !== data.inspectionId),
            { inspectionId: data.inspectionId, submittedId: submittedId ,previousSubmittedForm:response.data}
        ]);
        setShowFormDetails(false);
    }
}, [setSelectedInspectionIds, setShowFormDetails, useJhaFormContext]);


  const onCancelInspection = useCallback(() => {
    setShowFormDetails(false);
  }, [setShowFormDetails]);
  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      onPreviousButtonClick();
    },
  };
    return (
      <>
      {!showFormDetails && <Toolbar className='form-header'>
          <Item location="before"
            widget="dxButton"
            options={backButtonOptions} />
  
          <Item location="center"
            locateInMenu="never"
            text={'Safety'} />
        </Toolbar>}
    {!showFormDetails && <img src={preWorkInspectionsImage} alt="Pre-Work Inspections" style={{ width: '100%' }} />}

      {!showFormDetails ? <List
        dataSource={inspections}
        displayExpr="name"
        keyExpr="id"
        itemRender={renderItem}
        selectionMode="multiple"
        selectByClick={true}
        searchExpr="name"
        onItemClick={onSelectedItemKeysChange}
        selectedItemKeys={selectedInspectionIds}
      >
      </List>:(inspectionFormDetails && <DynamicForm formData={inspectionFormDetails} initialValues={selectedInspectionIds.find(i => i.inspectionId ==inspectionFormDetails.id)?.previousSubmittedForm} onCancel={onCancelInspection}  submitForm={onSubmitInspection}/>)
      }

    {!showFormDetails && <Toolbar className='form-buttons'>
        <Item
          widget='dxButton'
          location='before'
          options={previousButtonOptions}

        />
        <Item
          widget='dxButton'
          location='after'
          disabled={selectedInspectionIds.filter(d => d.submittedId).length <=0}
          options={nextButtonOptions} />
      </Toolbar>}
    </>
    
  );
};
export default PreWorkInspections;