import { withNavigationWatcher } from './contexts';
import { JhaPage, ChaPage, IncidentsPage, InspectionsPage } from './pages';

const routes = [

    {
        path: '/jha',
        element: JhaPage
    },
    {
        path: '/cha',
        element: ChaPage
    },
    {
        path: '/incidents',
        element: IncidentsPage
    },
    {
        path: '/inspections',
        element: InspectionsPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
