import { Button, List, LoadIndicator, Toolbar } from "devextreme-react";
import { useCallback, useEffect, useState } from "react";
import { activityService } from "../../../api";
import { useJhaFormContext } from "../context/jha-form-context";
import { IActivity } from "../../../interfaces";
import { useToast } from "../../../contexts";
import { Item } from "devextreme-react/cjs/toolbar";
import ReadOnlyFieldWithArrow from "../../Customizatons/CustomFIelds/readonly-field-with-arrow";
import "../jha-creation-multiview.scss";

const ActivitiesSelectionForm = () => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const { showErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activityList, setActivityList] = useState<IActivity[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<number[]>(formDetails.activities || []);

  const fetchActivities = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await activityService.getAllByRegion();
      if (response?.success) {
        setActivityList(response?.data);
      } else {
        console.error(response?.errors);
        showErrorToast("Error while fetching activities");
      }
    } catch (error) {
      console.error(error);
      showErrorToast("Network or server error");
    } finally {
      setIsLoading(false);
    }
  }, [showErrorToast]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const renderSelection = useCallback((data: IActivity) => {
      const isActivitySelected = selectedActivities?.includes(data.id);
      return (
        <div className={`list-item${isActivitySelected ? " selected" : ""}`}>
          <ReadOnlyFieldWithArrow
            value={data.name}
            displayCheck={isActivitySelected}
            onClick={() => console.log("Activity selected")}
          />
        </div>
      );
    }, [selectedActivities]);

  const onItemClick = useCallback((e: any) => {
    const selectedActivity = e.itemData.id;
    setSelectedActivities((prevSelected) =>
      prevSelected.includes(selectedActivity)
        ? prevSelected.filter((id) => id !== selectedActivity)
        : [...prevSelected, selectedActivity]
    );
  }, []);

  useEffect(() => {
    handleFormChange("activities", selectedActivities);
  }, [handleFormChange, selectedActivities]);

  return (
    <div className="activities-selection-form">
      <Toolbar className="activity-header-toolbar">
        <Item location="before">
          <Button
            icon="back"
            stylingMode="text"
            onClick={() => {
              prevStep();
            }}
          />
        </Item>
        <Item location="center" text="Add Activities" />
      </Toolbar>
      {isLoading ? (
        <div className="activity-list-loader">
          <LoadIndicator />
        </div>  
      ) : (
        <List
          className="activities-list"
          displayExpr="name"
          keyExpr="id"
          dataSource={activityList}
          selectionMode="multiple"
          selectByClick={true}
          searchEnabled={true}
          searchExpr="name"
          onItemClick={onItemClick}
          selectedItemKeys={selectedActivities}
          itemRender={renderSelection}
        />
      )}

      <Toolbar className="form-buttons">
        <Item location="before">
          <Button
            text="Previous"
            stylingMode="outlined"
            onClick={() => {
              prevStep();
            }}
          />
        </Item>
        <Item location="after">
          <Button
            text="Next"
            type="default"
            stylingMode="contained"
            onClick={() => {
              nextStep();
            }}
            disabled={selectedActivities.length === 0}
          />
        </Item>
      </Toolbar>
    </div>
  );
};

export default ActivitiesSelectionForm;