import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import './jha.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { List, Popup, Switch } from 'devextreme-react';
import { SwitchTypes } from 'devextreme-react/cjs/switch';
import { useLoad, useNavigation, useRegion, useToast } from '../../contexts';
import ConfirmationDialog from '../../dialogs/confirmation-dialog';
import { TextBox, Button as TextBoxButton, TextBoxTypes } from 'devextreme-react/text-box';
import { ButtonTypes } from 'devextreme-react/cjs/button';
import { JhaFormProvider } from '../../components/jha-cha-multistep-form/context/jha-form-context';
import MultiStepForm from '../../components/jha-cha-multistep-form/jha-creation-multiview';
import { FormType, IJobCreationForm, IPostWorkReview, JobStatus } from '../../interfaces';
import { jobService } from '../../api/common/JobService';
import JhaPostWorkReview from './components/post-work-review/post-work-review';
import JhaEdit from './components/edit/edit-job';
import { IJobEmail } from '../../interfaces/common/IJobEmail';
import JobEmail from '../../components/job-email-form/job-email';

enum JhaViews {
    Home,
    Creation,
    Edit,
    Completion,
    Email
}

export default function Jha() {
    const [currentView, setCurrentView] = useState(JhaViews.Home);
    const [isSearchBarVisible, setSearchBarVisible] = useState(false);
    const [showIncompleteJha, setShowIncompleteJha] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const { setShowHeader } = useNavigation();
    const { toggleRegionDialog } = useRegion();
    const [confirmationAction, setConfirmationAction] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const { showLoader } = useLoad();
    const { user } = useAuth0();
    const [submittedJhas, setSubmittedJhas] = useState<IJobCreationForm[]>([]);
    const { region } = useRegion();
    const { showSuccessToast,showErrorToast } = useToast();
    const [selectedJob, setSelectedJob] = useState<IJobCreationForm>();

    const fetchSubmittedJobs = useCallback(async () => {
        showLoader(true);
        try {
            var request = {
                regionId: Number(region),
                userId: user?.email || '',
                formType: FormType.JHA
            }
            const response = await jobService.getSubmittedJobs(request);
            if (response.success) {
                const sortedJobs = response.data.sort((a, b) =>
                    new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
                );
                setSubmittedJhas(sortedJobs);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader, region, user?.email]);

    useEffect(() => {
        fetchSubmittedJobs();
    }, [fetchSubmittedJobs]);

    useEffect(() => {
        setShowHeader(currentView === JhaViews.Home);
    }, [currentView, setShowHeader]);

    const showIncompleteJhaValueChange = useCallback((e: SwitchTypes.ValueChangedEvent) => {
        setShowIncompleteJha(e.value);
    }, []);

    const { logout } = useAuth0();

    const logoutButtonOptions = {
        icon: 'login',
        onClick: () => {
            setConfirmationMessage('Are you sure you want to Logout?');
            setConfirmationAction('logout');
        },
    };
    const regionDialogButtonOptions = {
        icon: 'globe',
        onClick: toggleRegionDialog
    };
    const addButtonOptions = {
        text: 'NEW',
        type: 'default',
        stylingMode: 'contained',
        onClick: () => {
            setCurrentView(JhaViews.Creation);
        },
    };
    const closeSearchButtonOptions = useMemo<ButtonTypes.Properties>(
        () => ({
            icon: 'back',
            stylingMode: 'text',
            onClick: () => {
                setSearchBarVisible(false);
                setSearchTerm('');
            }
        }),
        [],
    );
    const searchButtonOptions = {
        icon: 'search',
        onClick: () => {
            setSearchBarVisible(!isSearchBarVisible); // Toggle visibility
        },
    };
    const onCancel = useCallback(() => {
        setConfirmationMessage('Are you sure you want to Discard the changes?');
        setConfirmationAction('cancelJhaCreation');
    }, []);

    const onCreateJha = useCallback(async (formDetails: IJobCreationForm) => {
        var response = await jobService.create(formDetails);
        if (response.success) {
            showSuccessToast('JHA Successfully Created!');
            fetchSubmittedJobs();
            setCurrentView(JhaViews.Home);
        }
    }, [fetchSubmittedJobs, showSuccessToast]);

    const onConfirm = useCallback(() => {
        if (confirmationAction === 'logout') {
            localStorage.clear();
            logout({
                logoutParams: { returnTo: window.location.origin }
              });
        } else if (confirmationAction === 'cancelJhaCreation') {
            setConfirmationAction('');
            setCurrentView(JhaViews.Home);
        }
        setConfirmationAction('');
    }, [confirmationAction, logout]);

    const onCancelJhaCompletion = useCallback(() => {
        setCurrentView(JhaViews.Home);
    }, []);

    const onCompleteJha = useCallback(async (payload: IPostWorkReview) => {
        const treatedPayload: IPostWorkReview = { ...payload, isHazardsLeft: !!payload.isHazardsLeft, isInjuriesOccured: !!payload.isInjuriesOccured, istoolsAndMaterialsStored: !!payload.istoolsAndMaterialsStored };
        var response = await jobService.completeJob(treatedPayload);
        if (response.success) {
            setCurrentView(JhaViews.Home);
            showSuccessToast('JHA Successfully Completed!');
            fetchSubmittedJobs();
        }
    }, [fetchSubmittedJobs, showSuccessToast]);

    const onSaveJha = useCallback(async (formDetails: IJobCreationForm) => {
        var response = await jobService.update(formDetails.id.toString(), formDetails);
        if (response.success) {
            showSuccessToast('JHA Updated Successfully!');
            fetchSubmittedJobs();
            setCurrentView(JhaViews.Home);
        }
    }, [fetchSubmittedJobs, showSuccessToast]);
    const onSendEmail = useCallback(async (formDetails: IJobEmail) => {
        var response = await jobService.sendEmail(formDetails);
        if(response.success){
         showSuccessToast("Email Sent!");
         setCurrentView(JhaViews.Home);
        }
        else{
         showErrorToast("Error while Sending Email!")
        }
    }, [ showSuccessToast]);
    const renderListItem = (submittedJob: IJobCreationForm) => {
        return (
            <div className='card-container'>
                <Toolbar className='first-toolbar'>
                    <Item
                        location='before'
                        text={submittedJob?.jobDetails.siteName}
                    />
                    <Item
                        cssClass='item-value'
                        location='after'
                        text={submittedJob?.jobDetails.jobNumber || submittedJob?.jobDetails.workOrderNumber}
                    />
                </Toolbar>
                <Toolbar>
                    <Item
                        location='before'
                        render={() => <i className="dx-icon-event"></i>}
                    />
                    <Item
                        location='before'
                        text='Created On:'
                    />
                    <Item
                        location='after'
                        cssClass='item-value'
                        text={submittedJob?.createdDate ? new Date(submittedJob.createdDate + 'Z')?.toLocaleString() : new Date()?.toLocaleDateString()}
                    />
                </Toolbar>
                <Toolbar>
                    <Item
                        location='before'
                        text='Status:'
                    />
                    <Item
                        location='after'
                        cssClass={`item-value status-${submittedJob.jobStatus?.toLocaleLowerCase()}`}
                        text={submittedJob.jobStatus}
                    />
                </Toolbar>
                <Toolbar className='last-toolbar'>
                    <Item
                        location='center'
                        widget='dxButton'
                        options={{
                            icon: 'edit',
                            text: 'Edit',
                            onClick: () => {
                                setSelectedJob(submittedJob);
                                setCurrentView(JhaViews.Edit);
                            },
                        }}
                    />
                    <Item
                        location='center'
                        widget='dxButton'
                        options={{
                            icon: 'email',
                            text: 'Email',
                            onClick: () => {
                                setSelectedJob(submittedJob);
                                setCurrentView(JhaViews.Email);
                            },
                        }}
                    />
                    {
                        submittedJob.jobStatus === JobStatus.INCOMPLETE && (
                            <Item
                                location='center'
                                widget='dxButton'
                                options={{
                                    icon: 'check',
                                    text: 'Complete',
                                    onClick: () => {
                                        setSelectedJob(submittedJob);
                                        setCurrentView(JhaViews.Completion);
                                    },
                                }}
                            />
                        )
                    }
                </Toolbar>
            </div>
        );
    };

    const filteredJhas = submittedJhas.filter((jha) => {
        const matchesSearchTerm = searchTerm ? jha.jobDetails.siteName?.toLowerCase().includes(searchTerm.toLowerCase()) || jha.jobDetails.jobNumber?.toLowerCase().includes(searchTerm.toLowerCase()) || jha.jobDetails.workOrderNumber?.toLowerCase().includes(searchTerm.toLowerCase()) : true;
        const matchesIncompleteFilter = showIncompleteJha ? jha.jobStatus === JobStatus.INCOMPLETE : true;
        return matchesSearchTerm && matchesIncompleteFilter;
    });

    return (
        <div className='jha-home'>
            {(currentView === JhaViews.Home && !isSearchBarVisible) && (
                <Toolbar>
                    <Item location="before"
                        widget="dxButton"
                        options={logoutButtonOptions} />
                    <Item location="before"
                        widget="dxButton"
                        options={regionDialogButtonOptions} />
                    <Item location="center"
                        locateInMenu="auto"
                        text={'JHA'} />
                    <Item location="after"
                        locateInMenu="auto"
                        widget="dxButton"
                        options={searchButtonOptions} />
                    <Item location="after"
                        locateInMenu="auto"
                        widget="dxButton"
                        options={addButtonOptions} />
                </Toolbar>
            )}
            {isSearchBarVisible && (
                <TextBox
                    placeholder="Search JHAs"
                    showClearButton={true}
                    value={searchTerm}
                    onValueChange={(e) => setSearchTerm(e)}
                >
                    <TextBoxButton
                        name="closeSearch"
                        location="before"
                        options={closeSearchButtonOptions}
                    />
                </TextBox>
            )}
            {currentView === JhaViews.Home && (
                <Toolbar style={{ marginTop: '3px' }}>
                    <Item location="before"
                        locateInMenu="never"
                        text={'Incomplete JHAs only'}
                        cssClass='incomplete-jha' />
                    <Item location='after'>
                        <Switch
                            value={showIncompleteJha}
                            onValueChanged={showIncompleteJhaValueChange}
                        />
                    </Item>
                </Toolbar>
            )}
            {currentView === JhaViews.Home && (
                <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                    <List
                        className="jha-list"
                        dataSource={filteredJhas}
                        height="auto"
                        itemRender={renderListItem}
                    />
                </div>
            )}
            {currentView === JhaViews.Creation &&
                <JhaFormProvider onCancelJhaCreation={onCancel} onCreateJha={onCreateJha} formType={FormType.JHA}>
                    <MultiStepForm />
                </JhaFormProvider>
            }
            {currentView === JhaViews.Completion &&
                <JhaPostWorkReview initialForm={selectedJob} onCancelJhaCompletion={onCancelJhaCompletion} onCompleteJha={onCompleteJha} />
            }
            {currentView === JhaViews.Edit && !!selectedJob &&
                <JhaEdit initialForm={selectedJob} onCancel={onCancelJhaCompletion} onSave={onSaveJha} />
            }
            {currentView === JhaViews.Email && !!selectedJob &&
                <JobEmail initialForm={selectedJob} onCancel={onCancelJhaCompletion} onSend={onSendEmail} />
            }
            <ConfirmationDialog
                visible={!!confirmationAction}
                title="Confirmation"
                message={confirmationMessage}
                onCancel={() => { setConfirmationAction('') }}
                onConfirm={onConfirm}
                height={200}
            />
        </div>
    );
}
