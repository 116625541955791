import { IBase } from "../common";
import { ICyberHazardSelectionPoint } from "./ICyberHazardSelectionPoint";

export interface ICyberHazard extends IBase {
    selectionPoints:ICyberHazardSelectionPoint[]
    selectionMethod:SelectionMethod
}
export enum SelectionMethod{
    RadioButton,
    Checkbox
}