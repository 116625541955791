import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { IRegion } from '../interfaces';
import { locationService } from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import ListPickerDialog from '../dialogs/list-picker-dialog';

interface RegionContextType {
  region: string;
  setRegion: (value: string) => void;
  toggleRegionDialog: () => void;
  isRegionDialogVisible: boolean;
}

const RegionContext = createContext<RegionContextType | undefined>(undefined);

export const useRegion = (): RegionContextType => {
  const context = useContext(RegionContext);
  if (!context) {
    throw new Error('useRegion must be used within a RegionProvider');
  }
  return context;
};

interface RegionProviderProps {
  children: ReactNode;
}

export const RegionProvider: React.FC<RegionProviderProps> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const [region, setRegionState] = useState<string>(localStorage.getItem('region') || '');
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [isRegionDialogVisible, setIsRegionDialogVisible] = useState<boolean>(false);

  const fetchRegions = useCallback(async () => {
    const response = await locationService.getAll({ isActive: true });
    if (response.success) {
      setRegions(response.data);
    } else {
      console.error('Failed to fetch regions:', response.errors);
    }
  }, []);
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'region') {
        setRegionState(event.newValue || '');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);
  useEffect(() => {
    if (isAuthenticated && !region) {
      fetchRegions().then(() => setIsRegionDialogVisible(true));
    }
  }, [isAuthenticated, region, fetchRegions]);

  const setRegion = useCallback((value: string) => {
    localStorage.setItem('region', value);
    setRegionState(value);
    setIsRegionDialogVisible(false);
  }, []);

  const toggleRegionDialog = useCallback(() => {
    if(regions && regions.length >0)
      setIsRegionDialogVisible(!isRegionDialogVisible);
    else
      fetchRegions().then(() => setIsRegionDialogVisible(true));
  }, [isRegionDialogVisible]);

  return (
    <RegionContext.Provider value={{ region, setRegion, toggleRegionDialog, isRegionDialogVisible }}>
      {children}
      {isRegionDialogVisible && (
        <ListPickerDialog
          visible={isRegionDialogVisible}
          title="Location"
          onCancel={() => setIsRegionDialogVisible(false)}
          onSelected={(id) => setRegion(id.toString())}
          options={regions.map((r) => ({ id: r.id, name: r.name }))}
          height={200}
          disableCancel={!region}
          defaultValue={Number(region)}
        />
      )}
    </RegionContext.Provider>
  );
};
