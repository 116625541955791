// ChaHazardAnalysisComponent.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { CheckBox, Form, RadioGroup, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../context/jha-form-context';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ICyberHazard, SelectionMethod } from '../../../interfaces/cha/ICyberHazard';
import { useLoad } from '../../../contexts';
import { cyberHazardsService } from '../../../api';

type Selections = {
  [key: number]: number[];
};
const ChaHazardAnalysisComponent = () => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const { showLoader } = useLoad();
  const [chaHazards, setChaHazards] = useState<ICyberHazard[]>([]);
  const [selections, setSelections] = useState<Selections>({});

  const fetchCyberHazards = useCallback(async () => {
    showLoader(true);
    try {
      const response = await cyberHazardsService.getAllByRegion();
      if (response.success) {
        var activeItems = response.data?.filter(i => i.isActive);
        setChaHazards(activeItems);
        initializeSelections(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  const initializeSelections = (hazards: ICyberHazard[]) => {
    const initialSelections: Selections = {};
    hazards.forEach(hazard => {
        const detail = formDetails.chaHazardAnalysisDetails.find(d => d.chaAnalysisId === hazard.id);
        if (detail) {
            initialSelections[hazard.id] = detail.selectionPoints;
        } else {
            initialSelections[hazard.id] = [];
        }
    });
    setSelections(initialSelections);
};
  useEffect(() => {
    fetchCyberHazards();
  }, []);
  const handleSelectionChange = (hazardId: number, value: any) => {
    setSelections(prev => ({
      ...prev,
      [hazardId]: value
    }));
  };
  useEffect(() => {
    let updatedChaHazardDetails = [...formDetails.chaHazardAnalysisDetails]
    for (const [key, value] of Object.entries(selections)) {
      const numericKey = parseInt(key); 
      var hazard = updatedChaHazardDetails.find(i => i.chaAnalysisId == numericKey);
      if(!!hazard) hazard.selectionPoints=value;
      else updatedChaHazardDetails.push({
        chaAnalysisId:numericKey,
        selectionPoints:value
      })
  }
    handleFormChange('chaHazardAnalysisDetails', updatedChaHazardDetails);
  }, [selections])
  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      prevStep();
    },
  };
  const nextButtonOptions = {
    text: 'Next',
    type: 'default',
    stylingMode: 'contained',
    onClick: nextStep,
  };

  const previousButtonOptions = {
    text: 'Previous',
    type: 'default',
    stylingMode: 'outlined',
    onClick: prevStep,
  };
  return (
    <div className='step-form cha-hazard-form'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'CHA-Hazard Analysis'} />
      </Toolbar>
      <div style={{ maxHeight: '80vh', overflowY: 'auto', padding:'10px' }}>


        <Form>
          {chaHazards.map(hazard => (
            <GroupItem caption={hazard.name} key={hazard.id}>
              {hazard.selectionMethod === SelectionMethod.RadioButton ? (
                <RadioGroup
                  dataSource={hazard.selectionPoints}
                  displayExpr='description'
                  valueExpr='id'
                  value={selections[hazard.id] ? selections[hazard.id][0] : ''}
                  layout="vertical"
                  onValueChanged={(e) => handleSelectionChange(hazard.id, [e.value])}
                />
              ) : (
                <div>
                  {hazard.selectionPoints.map(sp => (
                    <div key={sp.id} style={{ marginBottom: '10px' }}>
                      <CheckBox
                      key={sp.id}
                      value={selections[hazard.id]?.includes(sp.id) || false}
                      text={sp.description}
                      onValueChanged={(e) => {
                        const updatedSelections = [...(selections[hazard.id] || [])];
                        if (e.value) {
                          updatedSelections.push(sp.id);
                        } else {
                          const index = updatedSelections.indexOf(sp.id);
                          updatedSelections.splice(index, 1);
                        }
                        handleSelectionChange(hazard.id, updatedSelections);
                      }}
                    />
                    </div>
                  ))}
                </div>
              )}
            </GroupItem>
          ))}
        </Form>
      </div>
      <Toolbar className='form-buttons'>
        <Item
          widget='dxButton'
          location='before'
          options={previousButtonOptions}

        />
        <Item
          widget='dxButton'
          location='after'
          disabled={(formDetails.chaHazardAnalysisDetails.length < chaHazards.length || formDetails.chaHazardAnalysisDetails.findIndex(i => i.selectionPoints.length ==0) !=-1 )}
          options={nextButtonOptions} />
      </Toolbar>
    </div>
  );
};

export default ChaHazardAnalysisComponent;
