import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Header, SideNavigationMenu } from '../../components';
import './side-nav-outer-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import { ButtonTypes } from 'devextreme-react/button';
import { TreeViewTypes } from 'devextreme-react/tree-view';
import type { SideNavToolbarProps } from '../../types';
import { useNavigation, useRegion } from '../../contexts';

export default function SideNavOuterToolbar({ title, children }: React.PropsWithChildren<SideNavToolbarProps>) {
  const scrollViewRef = useRef<ScrollView>(null);
  const navigate = useNavigate();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.Opened : MenuStatus.Closed
  );
  const { region } = useRegion();
  const {showHeader}  = useNavigation();

  return (
    <div className={'side-nav-outer-toolbar'}>
      {showHeader && <Header/>}
      <div className={'container'}>
        {!!region && <ScrollView key={region} ref={scrollViewRef} className={'layout-body with-footer'}>
        <div className={'content'}>
            {React.Children.map(children, (item: any, index) => {
              return React.cloneElement(item, { key: index + '-' + region });
            })}
          </div>
        </ScrollView>}
      </div>
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};
