import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import LoadPanel from 'devextreme-react/load-panel';

interface LoadContextType {
  // Updated to accept a boolean argument
  showLoader: (show: boolean) => void;
}

const LoadContext = createContext<LoadContextType | undefined>(undefined);

export const useLoad = (): LoadContextType => {
  const context = useContext(LoadContext);
  if (context === undefined) {
    throw new Error('useLoad must be used within a LoadProvider');
  }
  return context;
};

export const LoadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  // Updated to accept a boolean and set the loading state accordingly
  const showLoader = useCallback((show: boolean) => {
    setLoading(show);
  }, []);

  return (
    <LoadContext.Provider value={{ showLoader }}>
      {children}
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#app' }}
        visible={loading}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
    </LoadContext.Provider>
  );
};
