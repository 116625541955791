import { IChaAcknowledgement } from '../../interfaces/cha/IChaAcknowledgement';
import { ApiService } from '../ApiService';



class ChaAcknowledgement extends ApiService<IChaAcknowledgement> {
  constructor() {
    super('CyberHazard/Acknowledgement');
  }

  // Additional methods specific to the User model can be added here
}

export const chaAcknowledgementService = new ChaAcknowledgement();