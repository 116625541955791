import { IApiResponse, IPreworkInspection, IInspectionCreationForm, InspectionFormData } from '../../interfaces';
import { ApiService } from '../ApiService';
import axiosInstance from '../AxiosService';
import { AxiosResponse } from 'axios';


const endpoint=  'Inspection'
class InspectionService extends ApiService<IPreworkInspection> {
  constructor() {
    super(endpoint);
  }
  getFormDetails(id: number): Promise<IApiResponse<InspectionFormData>> {
    return axiosInstance.get(`${endpoint}/data/${id}`)
      .then((response: AxiosResponse) => this.handleFormResponse(response));
  }
  submitInspectionForm(payload: IInspectionCreationForm): Promise<IApiResponse<IInspectionCreationForm>> {
    return axiosInstance.post(`${endpoint}/submit`,payload)
      .then((response: AxiosResponse) => this.handleFormSubmissionResponse(response));
  }
  getInspectionSubmittedForm(id: number): Promise<IApiResponse<IInspectionCreationForm>> {
    return axiosInstance.get(`${endpoint}/submit/${id}`)
      .then((response: AxiosResponse) => this.handleFormSubmissionResponse(response));
  }
  getSubmittedInspections(email:string): Promise<IApiResponse<IInspectionCreationForm[]>> {
    return axiosInstance.get(`${endpoint}/submit?regionId=${localStorage.getItem('region')}&username=${email}`)
      .then((response: AxiosResponse) => this.handleFormSubmissionArrayResponse(response));
  }

  private handleFormResponse(response: AxiosResponse<IApiResponse<InspectionFormData>>): IApiResponse<InspectionFormData> {
    return response.data;
  }
  private handleFormSubmissionResponse(response: AxiosResponse<IApiResponse<IInspectionCreationForm>>): IApiResponse<IInspectionCreationForm> {
    return response.data;
  }
  private handleFormSubmissionArrayResponse(response: AxiosResponse<IApiResponse<IInspectionCreationForm[]>>): IApiResponse<IInspectionCreationForm[]> {
    return response.data;
  }
  // Additional methods specific to the User model can be added here
}

export const inspectionService = new InspectionService();