
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { ISpectrumJob, selectionFormMethods } from "../../../../interfaces";
import { erpService } from "../../../../api";
import { useLoad, useToast } from "../../../../contexts";
import { List, TextBox } from "devextreme-react";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useJhaFormContext } from "../../context/jha-form-context";

const SpectrumSearchForm = forwardRef<selectionFormMethods>((props, ref) => {
    const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
    const { showLoader } = useLoad();
    const { showErrorToast } = useToast();
    const [spectrumJobs, setSpectrumJobs] = useState<ISpectrumJob[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [noDataText, setNoDataText] = useState('Please search with Job Id')

    useImperativeHandle(ref, () => ({
        validateForm: () => {
            return !!formDetails.jobDetails.jobNumber;
        },
    }));
    
    const fetcSpectrumJobs = useCallback(async (jobId: string) => {
        if (jobId.length < 3) return;
        showLoader(true)
        try {
            setNoDataText('Searching...')
            const response = await erpService.getISpectrumJobs(jobId);
            if (response.success) {
                setSpectrumJobs([response.data]);
            }

        } catch (e) {
            console.log(e);
            showErrorToast("An error occurred while fetching Spectrum Jobs");
        }
        finally {
            showLoader(false);
            setNoDataText('No Data Found!')
        }

    }, [showLoader]);
    useEffect(() => {
        if(!!formDetails.jobDetails?.jobNumber)
            fetcSpectrumJobs(formDetails.jobDetails?.jobNumber)
    }, []);
    const renderListItem = useCallback((job: ISpectrumJob) => {
        return (
            <div className='card-container'>
                <Toolbar className='first-toolbar'>
                    <Item
                        location='before'
                        text={`Job #: ${job?.jobID}`}
                    />
                    <Item
                        location='after'
                        text={`CTC: ${job?.relatedCTC}`}
                    />
                </Toolbar>
                <Toolbar>
                    <Item
                        location='before'
                        render={() => <i className="dx-icon-globe"></i>}
                    />
                    <Item
                        location='before'
                        text={job.description}
                    />
                    <Item
                        location='after'
                        text={`${job.address?.city}, ${job.address.state} ${job.address?.zipCode}`}
                    />
                </Toolbar>
                <Toolbar className='last-toolbar'>
                    <Item
                        location='center'
                        widget='dxButton'
                        options={{
                            icon: formDetails.jobDetails?.jobNumber === job.jobID ? 'check' : 'plus',
                            text: formDetails.jobDetails?.jobNumber === job.jobID ? 'Selected' : 'Select',
                            type: 'default',
                            disabled: formDetails.jobDetails?.jobNumber === job.jobID,
                            onClick: () => {
                                handleFormChange('jobNumber', job?.jobID ?? '', 'jobDetails');
                                handleFormChange('coveringCTC', job?.relatedCTC ?? '', 'jobDetails');
                                handleFormChange('siteName', job?.description ?? '', 'jobDetails');
                            },
                        }}
                    />
                </Toolbar>
            </div>
        );
    }, [formDetails]);

    return <div className="spectrum-selection-form">
        <TextBox
            placeholder="Enter Job Id..."
            showClearButton={true}
            value={searchTerm}
            onValueChanged={(e) => setSearchTerm(e.value)}
            onKeyDown={(e: any) => {
                if (e.event.key === 'Enter') {
                    fetcSpectrumJobs(e.event.target.value);
                  }
            }}
            buttons={[
                {
                    name: 'search',
                    location: 'before',

                    options: {
                        icon: 'search',
                        type: 'default'

                    }
                }
            ]}
        ></TextBox>
        <div className="spectrum-joblist-container">
            <List
                className="spectrum-job-list"
                dataSource={spectrumJobs}
                height="auto"
                itemRender={renderListItem}
                noDataText={noDataText}
            />
        </div>
    </div>
})
export default SpectrumSearchForm;