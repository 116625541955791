// FormContext.js or FormContext.tsx if you're using TypeScript
import React, { createContext, useContext, useState } from 'react';
import { FormType, IJobCreationForm, JobType } from '../../../interfaces';
import { useAuth0 } from '@auth0/auth0-react';

interface JhaFormContextType {
  formDetails: IJobCreationForm;
  handleFormChange: (fieldName: string, value: string | number | boolean | any[], section?: string) => void;
  nextStep: () => void;
  prevStep: () => void;
  onSubmit: () => void;
  onDiscard: () => void;
  step: number;
}

interface JhaFormProviderProps {
  children: React.ReactNode;
  onCancelJhaCreation: () => void;
  onCreateJha: (formDetails: IJobCreationForm) => void;
  initialForm?: IJobCreationForm;
  formType: FormType;  // New required parameter to specify the form type
}
const FormContext = createContext<JhaFormContextType | undefined>(undefined);

export const useJhaFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useJhaFormContext must be used within a JhaFormProvider');
  }
  return context;
};

export const JhaFormProvider: React.FC<JhaFormProviderProps> = ({ children, onCancelJhaCreation, onCreateJha,formType,initialForm }) => {
  const { user } = useAuth0();
  const [formDetails, setFormDetails] = useState<IJobCreationForm>(initialForm || {
    // Initial state
    id: 0,
    createdDate: new Date(),
    regionId: Number(localStorage.getItem('region')) || 0,
    permitNumber: '0',
    musterPoint: '',
    personsTrainedInFirstAid: [],
    jobDetails: {
      jobNumber: undefined,
      coveringCTC: undefined,
      siteName: '',
      type: JobType['Job#']
    },
    colleagues: [
      {
        name:user?.name || '',
        email:user?.email || '',
        isTrainedInFirstAid:false,
        isDefault:true
      }
    ],
    activities: [],
    personalProtectiveEquipment: [],
    siteSpecificHazards: [],
    preworkInspectionDetails: [],
    jobHazardsAndControls: [],
    hseAcknowledgements: [],
    chaHazardAnalysisDetails: [],
    chaAcknowledgementId: 0,
    formType,
    attachments:[],
    isInspectionsSelected:true,
    isPPEsSelected:true,
    isSiteHazardsSelected:true
  });
  const [step, setStep] = useState(1);

  const handleFormChange = (fieldName: string, value: any, section?: string) => {
    if (section) {
      setFormDetails(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [fieldName]: value,
        },
      }));
    } else {
      setFormDetails(prev => ({
        ...prev,
        [fieldName]: value,
      }));
    }
  };

  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);

  const onSubmit = () => {
    onCreateJha(formDetails);
  };
  const onDiscard = () => {
    onCancelJhaCreation();
  };
  return (
    <FormContext.Provider value={{ formDetails, handleFormChange, nextStep, prevStep, onSubmit, step, onDiscard }}>
      {children}
    </FormContext.Provider>
  );
};
