import { IBase } from "../common";

export interface IPreworkInspection extends IBase {

}

export interface InspectionDetail {
    id: number;
    name: string;
    width: string;  // Assuming width is a string because it includes units like "100" which could imply "100%"
    position: number;
}

export interface InspectionQuestion {
    id: number;
    question: string;
    type: QuestionType;  // Could be an enum if the types are known and limited
    group: null | string;  // Using `null | string` based on provided data. Could be more specific if needed.
    position: number;
}
export interface InspectionFormData {
    name: string;
    id:number;
    details: InspectionDetail[];
    questions: InspectionQuestion[];
    additionalNotes:string;
}
export enum QuestionType {
    YesOrNo='Yes-No',
    PassOrFail='Pass-Fail'
}
export interface IInspectionCreationForm {
    additionalNotes: string;
    answers: Answer[];
    details: Detail[];
    id: number | null;
    images: string[];  // Specify more specific type if possible, such as string or string[] if it's URLs
    inspectionId: number;
    isPassed: boolean;
    jobId: number | null;
    source: string;
    createdDate:Date | null;
}

export interface Answer {
    comments: string | null;
    id: number;
    inspectionQuestionId: number;
    isAcceptable: boolean;
    isNotApplicable: boolean;
}

export interface Detail {
    data: string;
    id: number;
    inspectionDetailId: number;
}
