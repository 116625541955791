import { IRegion } from '../../interfaces';
import { ApiService } from '../ApiService';



class LocationService extends ApiService<IRegion> {
  constructor() {
    super('Location');
  }

  // Additional methods specific to the User model can be added here
}

export const locationService = new LocationService();