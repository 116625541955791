import { useCallback, useEffect, useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import './Header.scss';
import type { HeaderProps } from '../../types';
import { useNavigate, useLocation } from 'react-router-dom';
import { SelectionChangedEvent } from 'devextreme/ui/tabs';
import CustomPicker from '../../dialogs/list-picker-dialog';
import ListPickerDialog from '../../dialogs/list-picker-dialog';
import { useRegion } from '../../contexts';
import { useAuth0 } from '@auth0/auth0-react';

const tabsIconAndText = [
  {
    id: 0,
    text: 'JHA',
    icon: 'like',
    path:'/jha'
  },
  {
    id: 1,
    text: 'CHA',
    icon: 'taskcomplete',
    path:'/cha'
  },
  {
    id: 2,
    text: 'Inspections',
    icon: 'taskstop',
    path:'/inspections'
  },
  {
    id: 3,
    text: 'Incidents',
    icon: 'taskhelpneeded',
    path:'/incidents'
  }
];

export default function Header({  }: HeaderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;
  const [selectedTab, setSelectedTab] = useState(tabsIconAndText.findIndex(tab => tab.path === currentPath));
  const { isAuthenticated } = useAuth0();


  useEffect(() => {
    const tabIndex = tabsIconAndText.findIndex(tab => tab.path === currentPath);
    setSelectedTab( tabIndex);
  }, [currentPath]);
  const onNavigationChanged = useCallback((args:SelectionChangedEvent) => {
    navigate(args.addedItems[0]?.path ??'');
  }, [navigate]);

  return (
    <header id='i-safe-header' className={'header-component'}>
      <Tabs
            id="specialist-nav-menu"
            width={'100%'}
            defaultSelectedIndex={0}
            dataSource={tabsIconAndText}
            orientation={'horizontal'}
            iconPosition={'top'}
            stylingMode={'secondary'}
            selectedIndex={selectedTab}
            onSelectionChanged={onNavigationChanged}
          />
    </header>
  )
}
