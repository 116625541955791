// WorkOrderSelectionComponent.tsx
import React, { RefObject, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { TabPanel, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { ToolbarItem } from 'devextreme-react/cjs/popup';
import SpectrumSelectionForm from './work-order-selection-forms/spectrum-selection-form';
import D365SelectionForm from './work-order-selection-forms/d365-selection-form';
import ICareSelectionForm from './work-order-selection-forms/icare-selection-form';
import ManualSelectionForm from './work-order-selection-forms/manual-selection-form';
import { useJhaFormContext } from '../context/jha-form-context';
import { FormType, TabItem, selectionFormMethods } from '../../../interfaces';


const tabs:TabItem[] = [
  { id: 0, title: 'Spectrum', component: SpectrumSelectionForm },
  { id: 1, title: 'D365', component: D365SelectionForm },
  { id: 2, title: 'iCare WO', component: ICareSelectionForm },
  { id: 3, title: 'Manual', component: ManualSelectionForm }
];
const WorkOrderSelectionComponent = () => {
  const { formDetails, handleFormChange, nextStep ,onDiscard} = useJhaFormContext();
  const formRefs = useRef<{ [key: number]: RefObject<selectionFormMethods> }>({
    0: React.createRef(),
    1: React.createRef(),
    2: React.createRef(),
    3: React.createRef(),
  });
  const validateCurrentForm = (): boolean => {
    const currentFormRef = formRefs.current[selectedTabIndex];
    if (currentFormRef && currentFormRef.current) {
      const isValid = currentFormRef.current.validateForm();
      return isValid;
    }
    return false;
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleFormChange(name, value);
  };
  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      onDiscard();
    },
  };
  const nextButtonOptions = {
    text: 'Add Entry',
    type: 'default',
    stylingMode: 'contained',
    onClick: ()=>{
      if(validateCurrentForm())
         nextStep();
    },
};

const discardButtonOptions = {
    text: 'Discard',
    type: 'default',
    stylingMode: 'outlined',
    onClick: onDiscard,
};

  const handleSelectihandleFormChanged = (e: any) => {
    setSelectedTabIndex(e.component.option('selectedIndex'));
  };

  // Dynamically determine the ActiveComponent based on the selected tab index
  const item = () => {
    const SpecificComponent = tabs[selectedTabIndex].component;
    return <SpecificComponent ref={formRefs.current[selectedTabIndex]} />;
  };
  return (
    <div className='step-form'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={formDetails.formType === FormType.JHA ? 'Create JHA': 'Create CHA'} />
      </Toolbar>
      <TabPanel
        key={tabs[selectedTabIndex].title}
        className="dx-theme-background-color"
        width="100%"
        height={'100%'}
        animationEnabled={true}
        dataSource={tabs}
        tabsPosition={'top'}
        stylingMode={'secondary'}
        selectedIndex={selectedTabIndex}
        onSelectionChanged={handleSelectihandleFormChanged}
        itemRender={item}
      />
      <Toolbar className='form-buttons'>
        <Item 
          widget='dxButton'
          location='before'
          options={discardButtonOptions}
          
          />
          <Item 
          widget='dxButton'
          location='after'
          options={nextButtonOptions}/>
      </Toolbar>
    </div>
  );
};

export default WorkOrderSelectionComponent;
