import React from 'react';
import { Button } from 'devextreme-react/button';

interface ReadOnlyFieldWithTickProps {
  onClick?: () => void;
  value: string; // This will be the name
  description?: string; // This will be the email
  hideTick?:boolean;
}

const ReadOnlyFieldWithTick: React.FC<ReadOnlyFieldWithTickProps> = ({ onClick, value, description, hideTick }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span style={{
          fontSize: 'medium',
          fontWeight: 'bold',
          maxWidth: '75vw', // <-- Apply maxWidth
          whiteSpace: 'nowrap', // <-- Prevent text from wrapping
          overflow: 'hidden', // <-- Hide overflow
          textOverflow: 'ellipsis', // <-- Add ellipsis
        }}>{value}</span>
        {!hideTick && <Button
          icon="check"
          stylingMode="text"
          onClick={onClick}
          style={{
            height: '100% !important',
            color: '#00B578 !important',
            minWidth: '30px', // <-- Ensure the button has space
          }}
        />}
      </div>
      {!!description && <span style={{ fontSize: 'small' }}>{description}</span>}
    </div>
  );
};

export default ReadOnlyFieldWithTick;
