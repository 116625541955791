import { IJhaColleague } from "../jha/IJhaCreationForm";

export enum IncidentType {
    VEHICLE = "VEHICLE",
    GENERAL = "GENERAL"
}
export enum IncidentStatus {
    REPORTED = "REPORTED",
    CLOSED = "CLOSED",
    UNDER_INVESTIGATION ='UNDER_INVESTIGATION'
}
export interface IIncidentCreationForm {
    id: number;
    createdDate:Date;
    regionId: number;
    jobId:number;
    jobNumber:string;
    incidentDate:Date;
    description:string;
    isJhaCompleted:boolean;
    incidentType: IncidentType;
    generalIncident?: IGeneralIncident;
    vehicleIncident?: IVehicleIncident;
    images:string[];
    incidentStatus:IncidentStatus;
    ctc: string
}

export interface IGeneralIncident {
    affectedCollegue:IJhaColleague[];
    jobTitle: string;
    siteLocation:string;
    typeOfInjury:string;
    mechanismOfInjury:string;
}
export interface IVehicleIncident { 
    gbLossReference: string;
    associatedConvergentColleague: IJhaColleague[];
    claimant: string;
}