import React from 'react';
import { Button } from 'devextreme-react/button';

interface ReadOnlyFieldWithArrowProps {
  onClick: () => void;
  value: string;
  description?: string;
  displayCheck?:boolean
}

const ReadOnlyFieldWithArrow: React.FC<ReadOnlyFieldWithArrowProps> = ({
  onClick,
  value,
  description,
  displayCheck
}) => {
  return (
    
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span style={{
          fontSize: '16px',
          fontWeight:'500',
          color: 'rgba(0,0,0,.87)',
          maxWidth: '75vw', // <-- Apply maxWidth
          whiteSpace: 'nowrap', // <-- Prevent text from wrapping
          overflow: 'hidden', // <-- Hide overflow
          textOverflow: 'ellipsis', // <-- Add ellipsis
        }}>{value}</span>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>

        {displayCheck && <Button
          icon="check"
          stylingMode="text"
          style={{
            height: '100% !important',
            color: '#00B578 !important',
            minWidth: '30px', // <-- Ensure the button has space
          }}
        />}
        <Button
          icon="chevronnext"
          stylingMode="text"
          onClick={onClick}
          style={{
            height: '100% !important',
            color: '#00B578 !important',
            minWidth: '30px', // <-- Ensure the button has space
          }}
        />
        </div>
      </div>
      {!!description && <span style={{ fontSize: 'small' }}>{description}</span>}
    </div>
    
  );
};

export default ReadOnlyFieldWithArrow;
