// DynamicForm.tsx
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/form';
import { IInspectionCreationForm, InspectionFormData, QuestionType } from '../../interfaces';
import { Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { ButtonTypes } from 'devextreme-react/cjs/button';
import ISafeFileUploader from '../Customizatons/FileUploadComponent/FileUploadComponent';

interface DynamicFormProps {
    formData: InspectionFormData;
    onCancel: () => void;
    initialValues?: IInspectionCreationForm | null
    submitForm: (data: IInspectionCreationForm) => void; // You will define the type for `data` according to your API's expected request body.
}
const yesNoOptions = [
    { id: 0, text: 'Yes' },
    { id: 1, text: 'No' },
    { id: 2, text: 'NA' }
]
const passFailOptions = [
    { id: 0, text: 'Pass' },
    { id: 1, text: 'Fail' },
    { id: 2, text: 'NA' }
]
const DynamicForm: React.FC<DynamicFormProps> = ({ formData, submitForm, onCancel, initialValues }) => {

    const formRef = useRef<Form>(null);

    const mapCreationFormToState = (creationForm: IInspectionCreationForm) => {
        const formState = {
            id: creationForm.inspectionId, // Map inspectionId to id
            additionalNotes: creationForm.additionalNotes,
            // Map details to a format that can be understood by the form,
            // where the keys are 'details.{id}' and the values are the actual data
            details: creationForm.details.reduce((detailsState, detail) => ({
                ...detailsState,
                [`${detail.inspectionDetailId}`]: detail.data,
            }), {}),
            images:creationForm.images,
            // Map questions in a similar way as details
            questions: creationForm.answers.reduce((questionsState, answer) => ({
                ...questionsState,
                [`${answer.inspectionQuestionId}`]: answer.isNotApplicable ? 2 : (answer.isAcceptable ? 0 : 1),
            }), {}),
        };
        return formState;
    };
    const [formValues, setFormValues] = useState<any>(!!initialValues ? mapCreationFormToState(initialValues) : { id: formData.id });
    const uploadedFileIds = useCallback((ids : string[]) =>{
        setFormValues ({
            ...formValues,
            images:[...ids]
        })
    },[formValues, setFormValues]);
    const handleSubmit = useCallback(() => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            const isPassed = formData.questions.every(question => {
                const answer = formValues['questions'][`${question.id}`];
                return answer !== 1; // Check if answer ID is not 'No'
            });
            // Create the payload in the structure your API expects
            const payload = {
                additionalNotes: formValues.additionalNotes,
                answers: formData.questions.map(question => ({
                    comments: null, // Adjust if comments are editable
                    id: 0,
                    inspectionQuestionId: question.id,
                    isAcceptable: formValues['questions'][`${question.id}`] == 0,
                    isNotApplicable: formValues['questions'][`${question.id}`] == 2,
                })),
                details: formData.details.map(detail => ({
                    data: formValues[`details`][`${detail.id}`],
                    id: 0,
                    inspectionDetailId: detail.id,
                })),
                id: null,
                images: formValues.images,
                inspectionId: formData.id,
                isPassed: isPassed,
                jobId: null,
                source: "StandAlone",
                createdDate: null
            };
            submitForm(payload);
        }

    },[formValues, formData]);
 
    const previousButtonOptions = useMemo<ButtonTypes.Properties>(
        () => ({
            text: 'Cancel',
            type: 'default',
            stylingMode: 'outlined',
            onClick: () => {
                onCancel()
            },
        }),
        [],
    );
    const nextButtonOptions = useMemo<ButtonTypes.Properties>(
        () => ({
            text: 'Submit',
            type: 'default',
            stylingMode: 'contained',
            onClick: () => {
                handleSubmit();
            },
        }),
        [formValues],
    );
    const backButtonOptions = {
        icon: 'back',
        onClick: () => {
            onCancel();
        },
    };
    const questionEditiorOptions = (type: QuestionType) => {
        let options; //default
        switch (type) {
            case QuestionType.PassOrFail:
                options = passFailOptions;
                break;
            case QuestionType.YesOrNo:
                options = yesNoOptions;
                break;
            default:
                options = yesNoOptions;
                break;
        }
        return { items: options, valueChangeEvent: 'change', layout: 'horizontal', valueExpr: "id", displayExpr: "text" };
    }
    
    return (
        <>
            <Toolbar className='form-header'>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />

                <Item location="center"
                    locateInMenu="never"
                    text={formData.name + ' Form'} />
            </Toolbar>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>

                <Form
                    colCount={1}
                    formData={formValues}
                    ref={formRef}
                // ... handle field data changes if needed
                >
                    {formData.details.map((detail) => (
                        <SimpleItem key={detail.id} dataField={`details.${detail.id}`} editorType="dxTextBox" label={{ text: detail.name }}
                            editorOptions={{
                                placeholder: "Type here...",
                                showClearButton: true
                            }}>
                            <RequiredRule />
                        </SimpleItem>
                    ))}
                    {formData.questions.map((question) => (
                        <GroupItem key={question.id}>
                            <SimpleItem dataField={`questions.${question.id}`} editorType="dxRadioGroup"
                                editorOptions={{
                                    ...questionEditiorOptions(question.type)
                                }}
                            >
                                <Label text={question.question} />
                                <RequiredRule />
                            </SimpleItem>
                        </GroupItem>
                    ))}

                    <SimpleItem dataField="additionalNotes" editorType="dxTextArea" label={{ text: "Additional Notes" }} editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    </SimpleItem>


                </Form>
                <ISafeFileUploader initialFileIds={[...initialValues?.images ||[]]} uploadedFileIds={uploadedFileIds} />
            </div>
            <Toolbar className='form-buttons'>
                <Item
                    widget='dxButton'
                    location='before'
                    options={previousButtonOptions}

                />
                <Item
                    widget='dxButton'
                    location='after'
                    options={nextButtonOptions} />
            </Toolbar>
        </>


    );
};

export default DynamicForm;
