// ChaAcknowledgementComponent.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { CheckBox, HtmlEditor, RadioGroup, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../context/jha-form-context';
import { chaAcknowledgementService } from '../../../api/cha/ChaAcknowledgement';
import { useLoad } from '../../../contexts';
import { IChaAcknowledgement } from '../../../interfaces/cha/IChaAcknowledgement';



const ChaAcknowledgementComponent = () => {
  const { formDetails, handleFormChange, nextStep, prevStep, onSubmit } = useJhaFormContext();
  const [chaAcknowledgement, setChaAcknowledgement] = useState<IChaAcknowledgement>();
  const { showLoader } = useLoad();
  const [acknowledged, setAcknowledged] = useState(false);

  const fetchChaAcknowledgement = useCallback(async () => {
    showLoader(true);
    try {
      const response = await chaAcknowledgementService.getAllByRegion();
      if (response.success && !!response.data) {
        var activeItem = response.data[0];
        setChaAcknowledgement(activeItem);
        if (formDetails.CHAAcknowledgementId === activeItem.id) {
          setAcknowledged(true);
        }
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  useEffect(() => {
    fetchChaAcknowledgement();
  }, []);

  const backButtonOptions = {
    icon: 'back',
    onClick: () => {
      prevStep();
    },
  };
  const submitButtonOptions = {
    text: 'Create',
    type: 'default',
    stylingMode: 'contained',
    onClick: onSubmit,
  };

  const previousButtonOptions = {
    text: 'Previous',
    type: 'default',
    stylingMode: 'outlined',
    onClick: prevStep,
  };
  const handleAcknowledgeChange = (e: any) => {
    setAcknowledged(e.value);
    handleFormChange('CHAAcknowledgementId', e.value ? (chaAcknowledgement ? chaAcknowledgement.id : 0) : 0);
  };
  return (
    <div className='step-form cha-acknowledgement'>
      <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'CHA Acknowledgement'} />
      </Toolbar>
      <div style={{ height: '80vh', overflowY: 'auto', padding: '10px' }}>
        <HtmlEditor
          visible
          value={chaAcknowledgement?.description}
          disabled
          valueType={'html'}
        >

        </HtmlEditor>
      </div>
      <CheckBox
        style={{ padding: '10px' }}
        value={acknowledged}
        text="I have read and acknowledged"
        onValueChanged={handleAcknowledgeChange}
      />
      <Toolbar className='form-buttons'>

        <Item
          widget='dxButton'
          location='before'
          options={previousButtonOptions}

        />
        <Item
          widget='dxButton'
          location='after'
          disabled={!acknowledged}
          options={submitButtonOptions} />
      </Toolbar>
    </div>
  );
};

export default ChaAcknowledgementComponent;
