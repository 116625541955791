import { useState } from 'react';
import { useJhaFormContext } from '../context/jha-form-context';
import PersonalProtectiveEquipment from './safety-forms/personal-protective-form';
import PreWorkInspections from './safety-forms/pre-work-inspection-form';
import SiteSpecificHazards from './safety-forms/site-specific-hazards-form';

export interface SafetySubFormProps {
  onNextButtonClick: () => void;
  onPreviousButtonClick: () => void;
}
const tabList = [
  { name: 'Pre-Work Inspections', component: PreWorkInspections },
  { name: 'Personal Protective Equipment', component: PersonalProtectiveEquipment },
  { name: 'Site Specific Hazards', component: SiteSpecificHazards },
];
const SafetyComponent = () => {
  const { nextStep, prevStep } = useJhaFormContext();
  const [currentTab, setCurrentTab] = useState(0);
  const CurrentTabComponent = tabList[currentTab].component;

  const onPreviousButtonClick=() =>{
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
    else {
      prevStep();
    }
  }
  const onNextButtonClick=() =>{
    if (currentTab < 2) {
      setCurrentTab(currentTab + 1);
    }
    else {
      nextStep();
    }
  }

  return (
    <div className='step-form safety-form'>
      <CurrentTabComponent 
        onNextButtonClick={onNextButtonClick}
        onPreviousButtonClick={onPreviousButtonClick}
      />
    </div>
  );
};

export default SafetyComponent;

