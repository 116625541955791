import React, { useState } from 'react';
import Popup, { IPopupOptions, ToolbarItem } from 'devextreme-react/popup';

interface ConfirmationDialogProps {
    visible: boolean;
    title: string;
    message: string;
    onCancel: () => void;
    onConfirm: () => void;
    height:number;
}
const animationConfig: IPopupOptions['animation'] = {
    show: {
        type: 'slide',
        from: {
          position:{
            my:'center',
            at:'center',
            of: window
          }
        },
        to: {
            position:{
                my:'top',
                at:'bottom',
                of: window
              }
        },
      }
  };
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    visible,
    title,
    message,
    onCancel,
    onConfirm,
    height
}) => {
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onCancel,
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default',
        stylingMode: 'contained',
        onClick: onConfirm,
    };
    
    return (
        <Popup
            visible={visible}
            className='confirmation-dialog'
            title={title}
            onHiding={onCancel}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showTitle={true}
            position={'bottom'}
            width={390}
            height={height ? height:238}
            showCloseButton
            animation={{
                show: {
                    type: 'slide',
                    from: {
                        position: {
                            my: 'top',
                            at: 'bottom',
                            of: window
                        }
                    },
                    to: {
                        position: {
                            my: 'bottom',
                            at: 'bottom',
                            of: window
                        }
                    }
                }
            }}
        >
            <p>{message}</p>
            <ToolbarItem
                options={cancelButtonOptions}
                widget="dxButton"
                location="before"
                toolbar="bottom"
            />
            <ToolbarItem
                options={confirmButtonOptions}
                widget="dxButton"
                location="after"
                toolbar="bottom"
            />
        </Popup>
    );
};

export default ConfirmationDialog;
