import { ButtonTypes } from 'devextreme-react/cjs/button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SafetySubFormProps } from '../safety-form';
import ppeImage from './images/step2.png';
import { List, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { useJhaFormContext } from '../../context/jha-form-context';
import { ppeService } from '../../../../api';
import { useLoad } from '../../../../contexts';
import ConfirmationDialog from '../../../../dialogs/confirmation-dialog';
import { IPpe } from '../../../../interfaces';
import ReadOnlyFieldWithTick from '../../../Customizatons/CustomFIelds/readonly-field-with-tick';


const PersonalProtectiveEquipment: React.FC<SafetySubFormProps> = ({
  onNextButtonClick,
  onPreviousButtonClick
}) => {
  const { formDetails, handleFormChange, nextStep, prevStep } = useJhaFormContext();
  const { showLoader } = useLoad();
  const [ppes, setPpes] = useState<IPpe[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [selectedPpeIds, setSelectedPpeIds] = useState<number[]>(formDetails.personalProtectiveEquipment || []);
  const fetchPpes = useCallback(async () => {
    showLoader(true);
    try {
      const response = await ppeService.getAllByRegion();
      if (response.success) {
        let activeItems = response.data?.filter(i => i.isActive) || [];
        const noneItem = { id: -1, name: 'None', isActive: true, createdDate:new Date(), regionId:-1, isMandatory:false };
        activeItems?.sort((a, b) => a.name.localeCompare(b.name));

      // Add the 'None' item to the beginning of the activeItems array
        activeItems = [noneItem, ...activeItems];
        setPpes(activeItems);
      } else {
        console.error(response.errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showLoader(false);
    }
  }, []);
  useEffect(() => {
    fetchPpes();
  }, []);
  useEffect(() => {
    var ppes = selectedPpeIds.filter(id => id != -1)
    handleFormChange('personalProtectiveEquipment', ppes);
    handleFormChange('isPPEsSelected', ppes.length >0)
  }, [selectedPpeIds]);
  const validateMandatoryPpes = useCallback(() => {
    const mandatoryPpes = ppes.filter(ppe => ppe.isMandatory);
    const allMandatorySelected = mandatoryPpes.every(mandatoryPpe => selectedPpeIds.includes(mandatoryPpe.id));

    return allMandatorySelected;
  }, [ppes, selectedPpeIds]);
  const previousButtonOptions = useMemo<ButtonTypes.Properties>(
    () => ({
      text: 'Previous',
      type: 'default',
      stylingMode: 'outlined',
      onClick: () => {
        onPreviousButtonClick()
      },
    }),
    [],
  );
  const nextButtonOptions = useMemo<ButtonTypes.Properties>(
    () => ({
      text: 'Next',
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        if (validateMandatoryPpes()) {
          onNextButtonClick();
        } else {
          setShowWarning(true);
        }
      },
    }),
    [ppes,selectedPpeIds],
  );
  const renderItem = useCallback((data: IPpe) => {
    const isSelected = selectedPpeIds.includes(data.id);
    return (
      <div className={`list-item${isSelected ? ' selected' : ''}`}>
        {isSelected
          ? <ReadOnlyFieldWithTick value={data.name} onClick={() => setSelectedPpeIds((ppeList) => ppeList.filter(a => a!=data.id))} />
          : <span style={{ fontSize:'medium', fontWeight:'bold' }}>{data.name}</span>
        }
      </div>
    );
  }, [selectedPpeIds]);
  const onSelectedItemKeysChange = useCallback((e: any) => {
    if (e.name === 'selectedItemKeys') {
      setSelectedPpeIds(e.value);
    }
  }, [setSelectedPpeIds]);
  const onConfirm = useCallback(() => {
    setShowWarning(false);
    onNextButtonClick();
}, []);
const backButtonOptions = {
  icon: 'back',
  onClick: () => {
    onPreviousButtonClick();
  },
};
  return (
    <>
    <Toolbar className='form-header'>
        <Item location="before"
          widget="dxButton"
          options={backButtonOptions} />

        <Item location="center"
          locateInMenu="never"
          text={'Safety'} />
      </Toolbar>
      <div style={{ maxHeight: '75vh' }}>
        <img src={ppeImage} alt="Personal Protective Equipment" style={{ width: '100%' }} />
        <List
          dataSource={ppes}
          displayExpr="name"
          keyExpr="id"
          itemRender={renderItem}
          selectionMode="multiple"
          selectByClick={true}
          searchExpr="name"
          onOptionChanged={onSelectedItemKeysChange}
          selectedItemKeys={selectedPpeIds}
        >
        </List>
      </div>
      <Toolbar className='form-buttons'>
        <Item
          widget='dxButton'
          location='before'
          options={previousButtonOptions}

        />
        <Item
          widget='dxButton'
          location='after'
          disabled={selectedPpeIds.length <=0}
          options={nextButtonOptions} />
      </Toolbar>
      <ConfirmationDialog
                visible={showWarning}
                title="Confirmation"
                message={'You have not selected other mandatory PPEs. Are you sure that you want to proceed?'}
                onCancel={()=>{setShowWarning(false)}}
                onConfirm={onConfirm}
                height={200}
            />
    </>

  );
};
export default PersonalProtectiveEquipment;