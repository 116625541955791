import { IHseAcknowledgement } from '../../interfaces';
import { ApiService } from '../ApiService';



class HseAcknowledgementService extends ApiService<IHseAcknowledgement> {
  constructor() {
    super('HSE');
  }

  // Additional methods specific to the User model can be added here
}

export const hseAcknowledgementService = new HseAcknowledgementService();