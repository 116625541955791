import { IJobHazard } from '../../interfaces';
import { ApiService } from '../ApiService';



class JobHazardsService extends ApiService<IJobHazard> {
  constructor() {
    super('JobHazard');
  }

  // Additional methods specific to the User model can be added here
}

export const jobHazardsService = new JobHazardsService();