import { AxiosResponse } from 'axios';
import { IApiResponse, IJobCreationForm, ISearchJobsRequest } from '../../interfaces';
import { ApiService } from '../ApiService';
import axiosInstance from '../AxiosService';
import { IPostWorkReview } from '../../interfaces/jha/IPostWorkReview';
import { IJobEmail } from '../../interfaces/common/IJobEmail';


const endpoint=  'Job'
class JobService extends ApiService<IJobCreationForm> {
  constructor() {
    super(endpoint);
  }
  getSubmittedJobs(request:ISearchJobsRequest): Promise<IApiResponse<IJobCreationForm[]>> {
    return axiosInstance.post(`${endpoint}/Search`,request)
      .then((response: AxiosResponse) => this.handleJobSearchResults(response));
  }
  completeJob(payload: IPostWorkReview): Promise<IApiResponse<IPostWorkReview>> {
    return axiosInstance.post(`${endpoint}/${payload.jobId}/complete`,payload)
      .then((response: AxiosResponse) => this.handleCompleteJobResponse(response));
  }
  sendEmail(payload: IJobEmail): Promise<IApiResponse<any>> {
    return axiosInstance.post(`${endpoint}/${payload.jobId}/Email`,payload)
      .then((response: AxiosResponse) => this.handleJobEmailResponse(response));
  }
  private handleCompleteJobResponse(response: AxiosResponse<IApiResponse<IPostWorkReview>>): IApiResponse<IPostWorkReview> {
    return response.data;
  }
  private handleJobEmailResponse(response: AxiosResponse<IApiResponse<any>>): IApiResponse<any> {
    return response.data;
  }
  private handleJobSearchResults(response: AxiosResponse<IApiResponse<IJobCreationForm[]>>): IApiResponse<IJobCreationForm[]> {
    return response.data;
  }
  // Additional methods specific to the User model can be added here
}

export const jobService = new JobService();